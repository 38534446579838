import React, { useEffect, useState } from "react";

import "../css/editions.css";
import "../css/cardComponent.css";
import Footer from "../../partials/components/Footer";
import { motion } from "framer-motion";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import LoadScreen from "../../others/components/loadScreen";
import FlipCardComponent from "../../../components/FlipCard/FlipCard";
import BackBtn from "../../others/components/backBtn";

const EditionsPage = () => {
  const bannerImg =
    "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/banner-editions.jpg";
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);
  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="content editions">
        <div
          className="banner"
          style={{ backgroundImage: 'url("' + bannerImg + '"' }}
        >
          <div className="text-content">
            <img
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/phirst-editions-logo-3-500.png"
              className="header-img-editions"
            ></img>
            <p>
              Welcome to PHirst Editions, where we offer luxury dwelling options
              for mid income earners who aspire for more than just a home. Our
              exquisite new designs, expansive homes, and top-of-the-line
              features are tailored to showcase your successes and triumphs in
              life.
            </p>
          </div>
          <div className="color-overlay"></div>
        </div>
        <div className="properties">
          <div className="background-overlay"></div>
          <h2>Choose Your Property</h2>
          <div className="flip-card-container">
            <FlipCardComponent
              title="Cartland 54"
              cardID="cartland54"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/cartland-54.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/cartland-54-exterior.jpg"
              specs={[
                "Floor Area: 54sqm",
                "Typical Lot Area: 88sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={1}
              color="#003262"
            />
            <FlipCardComponent
              title="Charles 70"
              cardID="charles70"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-70.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-70-exterior.jpg"
              specs={[
                "Floor Area: 70sqm",
                "Typical Lot Area: 99sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/charles"
              cardKey={2}
              color="#003262"
            />
            <FlipCardComponent
              title={
                <>
                  Charles 150 <br /> Expanded Version
                </>
              }
              cardID="charles150"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-150.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-150-exterior.jpg"
              specs={[
                "Floor Area: 150sqm",
                "Typical Lot Area: 99sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/charles-expanded"
              cardKey={3}
              color="#003262"
            />
            <FlipCardComponent
              title="Christie 90"
              cardID="christie90"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/christie-90.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/christie-90-exterior.jpg"
              specs={[
                "Floor Area: 90sqm",
                "Typical Lot Area: 110sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/christie"
              cardKey={4}
              color="#003262"
            />
            <FlipCardComponent
              title="Corin 120"
              cardID="corin120"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/corin-120.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/corin-120-exterior.jpg"
              specs={[
                "Floor Area: 120sqm",
                "Typical Lot Area: 132sqm",
                "3 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/corin"
              cardKey={5}
              color="#003262"
            />
          </div>
          <div className="editionScene">
            <EditionsCardComponent
              title="Cartland 54"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/cartland-54.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/cartland-54-exterior.jpg"
              specs={[
                "Floor Area: 54sqm",
                "Typical Lot Area: 88sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
            />
            <EditionsCardComponent
              title="Charles 70"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-70.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-70-exterior.jpg"
              specs={[
                "Floor Area: 70sqm",
                "Typical Lot Area: 99sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/charles"
            />
            <EditionsCardComponent
              title="Christie 90"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/christie-90.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/christie-90-exterior.jpg"
              specs={[
                "Floor Area: 90sqm",
                "Typical Lot Area: 110sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/christie"
            />
            <EditionsCardComponent
              title="Corin 120"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/corin-120.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/corin-120-exterior.jpg"
              specs={[
                "Floor Area: 120sqm",
                "Typical Lot Area: 132sqm",
                "3 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/corin"
            />
            <EditionsCardComponent
              title="  Charles 150 Expanded Version"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-150.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/editions/charles-150-exterior.jpg"
              specs={[
                "Floor Area: 150sqm",
                "Typical Lot Area: 99sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "2 Carport",
              ]}
              link="https://www.phirsteditions.com.ph/charles-expanded"
            />
          </div>
        </div>
        <div className="editionPillar">
          <div className="editionPillar-content">
            <p>
              <b className="editionWrapper-pillar">
                <motion.span
                  className="editionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  Expand
                </motion.span>

                <b className="editionMain-text">Expand</b>
              </b>{" "}
              your vision to new horizons.
            </p>
            <p>
              Awaken your senses with these{" "}
              <b className="editionWrapper-pillar">
                <motion.span
                  className="editionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
                  viewport={{ once: true }}
                >
                  Exceptional
                </motion.span>

                <b className="editionMain-text">Exceptional</b>
              </b>{" "}
              homes.
            </p>
            <p>
              Connect with{" "}
              <b className="editionWrapper-pillar">
                <motion.span
                  className="editionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 2 }}
                  viewport={{ once: true }}
                >
                  Ease
                </motion.span>

                <b className="editionMain-text">Ease </b>
              </b>{" "}
              to life’s conveniences.
            </p>
            <p>
              Revel in an{" "}
              <b className="editionWrapper-pillar">
                <motion.span
                  className="editionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 3 }}
                  viewport={{ once: true }}
                >
                  Elevated
                </motion.span>

                <b className="editionMain-text">Elevated </b>
              </b>{" "}
              living experience.
            </p>
          </div>
        </div>
        <div className="video-embed">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/g2zr-glYQQU?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <a
            className="editionBtn"
            href="https://www.phirsteditions.com.ph"
            target="_blank"
          >
            <span class="edition__icon-wrapper">
              <svg
                width="10"
                class="edition__icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>

              <svg
                class="edition__icon-svg edition__icon-svg--copy"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>
            </span>
            Visit the Website to learn more
          </a>
        </div>
        <ContactFormTemplate email="phirsteditions@gmail.com" />
        <Footer />
      </div>
    </>
  );
};

const EditionsCardComponent = (props) => {
  return (
    <div className="card">
      <div className="card__face card__face--front">
        <div className="card-title">{props.title}</div>
        <div className="img-gradient"></div>
        <img src={props.imgFront} />
      </div>
      <div className="card__face card__face--back">
        <div className="editionSpecification">
          <b className="">Specifications</b>
          {props.specs.map((item, key) => {
            return <div key={key}>{item}</div>;
          })}
          <a href={props.link} target="_blank">
            See More
          </a>
        </div>
        <img src={props.imgBack} />
      </div>
    </div>
  );
};
export default EditionsPage;
