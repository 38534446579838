import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

const centraleImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/landing/Phirst-Centrale-202404190624.jpg'
const impressionsImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/impressions-tablet.jpg'
const editionsImg ='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/landing/Impressions-Hermosa-202404190610.jpg'
const pphiImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/pphi-banner-v2.JPG'
const sightsImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/marketing-tent-main-bg.jpg'


const AdditionalDetails = props => {
    const [ isBalcony, setBalcony] = useState(false)

    return (
        <div className="additional-details">
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-floor-area.png" alt="" loading="lazy"/>
                <h4>{ props.floorArea }</h4>
                <small>Floor area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-lot-area.png" alt="" loading="lazy"/>
                <h4>{ props.lotArea}</h4>
                <small style={{textAlign: "center"}}>Typical<br />Lot area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-shower2.png" alt="" loading="lazy"/>
                <h4>{ props.bathroom }</h4>
                <small>Bathrooms</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bed.png" alt="" loading="lazy"/>
                <h4>{ props.bedroom }</h4>
                <small>Bedrooms</small>
            </div>
            <div className="detail">
                <img src={ isBalcony 
                    ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-balcony.png" 
                    : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-carport.png"} alt="" loading="lazy"/>
                { isBalcony ?
                    <>
                        <h4>{ props.balcony }</h4>
                        <small>Balcony</small>
                    </>
                    :
                    <>
                        <h4>{ props.carport }</h4>
                        <small>Carport</small>
                    </>
                }
            </div>
        </div>
    )
}

const Residences = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 24) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + editionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">Residences</h4>
                <p>PHirst offers a range of residential properties tailored to meet the needs of a wide market segment spread across the Philippines. Our aim is to enhance and redefine the quality of life for more Filipinos by delivering first-homes that prioritize quality above all else.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/about-us" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Township = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY - 100) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + centraleImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">Township</h4>
                <p>We create estates that are beyond places to work and dwell in. We design them to help you and your families reach your full potential. Each estate combines quality residences with commercial properties to stimulate local economic growth and to keep you connected to a vibrant community.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-centrale" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const PhirstParkHomes = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 24) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + pphiImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst Park Homes</h4>
                <p>PHirst aims to redefine modern living in the Philippines by offering top-tier residential properties that foster a sense of community and convenience. Our homes are designed to cater to various lifestyles and aspirations, providing a range of options that ensure every family can find their perfect future home.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-park-homes" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Sights = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 24) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + sightsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst <span>Sights</span></h4>
                <p>Welcome to PHirst Sights, your ultimate solution for affordable and stylish housing in the Philippines. Our brand specializes in economic horizontal homes, featuring both the chic loft-style Alora Series rowhouses and the cozy Amani Series townhouses.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-sights" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Impressions = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 100) }}>
            <div className="modal-body impressions">
                <div className="modal-img" style={{ backgroundImage: "url(" + impressionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="impressions-title">PHirst <span>Impressions</span></h4>
                <p>Experience the magic of urban living at PHirst Impressions, where comfortable suburban living meets a community that fosters meaningful connections. Make PHirst Impressions your new home in the heart of PHirst Centrale, where convenience and comfort awaits.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-impressions" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Editions = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 24) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + editionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst <span>Editions</span></h4>
                <p>PHirst offers a range of residential properties tailored to meet the needs of a wide market segment spread across the Philippines. Our aim is to enhance and redefine the quality of life for more Filipinos by delivering first-homes that prioritize quality above all else.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-editions" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Centrale = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY - 100) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + centraleImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst <span>Centrale</span></h4>
                <p>PHirst creates townships tailored to your lifestyle. Our neighborhoods blend unique character with homes designed for work and leisure, fostering joyful moments with loved ones. Situated in bustling districts or growing centers, our townships keep you connected to a vibrant community.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-centrale" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

export { Residences, Township, Centrale, Editions, PhirstParkHomes, Sights, Impressions }