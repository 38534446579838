import React, { useEffect, useState } from "react";
import "../css/explore.css";
import LoadScreen from "../../others/components/loadScreen";
import BackBtn from "../../others/components/backBtn";
import Footer from "../../partials/components/Footer";

const Explore = () => {
  const [loadState, setLoadState] = useState(false);
  useEffect(() => {
    setLoadState(true);
  }, []);
  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="explore-container">
        <div className="explore-hero">
          <img
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/exploreicon.png"
            alt="Icon"
          />
          <h1>News & Articles</h1>
        </div>
        <div className="explore-content">
          <div className="article-content">
            <div className="article-date">
              <b>August 7, 2024</b>
            </div>

            <h1>
              Century Properties Group’s PHirst rewards Olympic Double Gold
              Medalist Carlos Yulo with House & Lot Package
            </h1>
            <p>
              PHirst, the First-Home brand of listed developer Century
              Properties Group (CPG), has announced that it will award a house
              and lot package to Carlos Yulo for his outstanding achievement in
              winning two gold medals in gymnastics at the 2024 Paris Olympics.
              <br />
              <br />
              “On behalf of PHirst and the entire Century Properties Group, we
              extend our sincerest gratitude and heartfelt congratulations to
              Carlos Yulo for his historic double gold in the Olympics, bringing
              pride to the Philippines,” said Amb. Jose E.B. Antonio, Executive
              Chairman of CPG. “We also extend our congratulations to Cynthia
              Carreon, President of the Gymnastics Association of the
              Philippines for the stewardship and guidance of Carlos Yulo all
              through the years.”
              <br />
              <br />
              CPG is presenting Yulo with a house and lot at its PHirst Editions Batulao project in Nasugbu, Batangas. The single attached home, coincidentally named Charles – translating to Carlos in Spanish – is valued at Php6.0 million.
              <br />
              <br />
              “We at PHirst celebrate Yulo’s triumph. His story represents the journey of every Filipino toward their dreams. We are honored to offer Carlos a ‘home of many firsts’ and wish him continued success as he pursues his dreams and creates more memorable moments both on a professional and personal level,” remarked Ricky M. Celis, President of PHirst Park Homes, Inc. and Century PHirst Corporation.
              <br />
              <br />
              “Carlos started training at the age of seven and has relentlessly pursued his dream every day since. He embodies grit and perseverance. We celebrate his remarkable achievement of winning two gold medals at the Olympics, which has not only brought glory to our nation but also inspired countless Filipinos,” said Marco R. Antonio, President and CEO of Century Properties Group.
              <br />
              <br />
              This gesture marks the second time that CPG has awarded a house and lot to a Filipino Olympic gold medalist. In 2021, CPG also honored Hidilyn Diaz with a PHirst house and lot package for her remarkable accomplishments as the Philippines’ first-ever Olympic gold medalist in Tokyo 2020.
              <br />
              <br />
              “We have been consistent with our desire to reward our outstanding Olympians. It started with the champion weightlifter Hidilyn Diaz who won our first-ever Olympic gold, and now we are once again delighted to gift Carlos a PHirst home, a ‘Home of Champions!’” Amb. Antonio adds. PHirst has captured the first-home buyer market with its master planned communities bearing the 4Cs: Complete and well-provisioned homes with a perimeter fence and gate, Conceptive amenities that promote a healthy and holistic lifestyle, Connected living through WiFi zones and a shuttle service, and a Convenient buying experience. PHirst currently has 23 communities and counting spread out in key growth areas in Luzon and Visayas. For more information visit the official <a href="https://century-phirst.com/" className="social-link" target="_blank">website</a> and its <a href="https://www.facebook.com/PHirstOfficialFacebookPage" className="social-link" target="_blank">Facebook</a> page.
            </p>
            <div className="read-more">
              <a href="https://www.century-properties.com/cpg-rewards-carlos-yulo/" target="_blank">
                Read more
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Explore;
