import React, { useEffect, useState } from "react";

import "../css/impressions.css";
import "../css/cardComponent.css";
import Footer from "../../partials/components/Footer";
import LoadScreen from "../../others/components/loadScreen";
import { motion } from "framer-motion";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackBtn from "../../others/components/backBtn";
import FlipCardComponent from "../../../components/FlipCard/FlipCard";

const ImpressionsPage = () => {
  const bannerImg =
    "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/impressions-banner.jpg";
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);
  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="content impressions">
        <div
          className="banner"
          style={{ backgroundImage: 'url("' + bannerImg + '"' }}
        >
          <div className="text-content">
            <img
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/impressions/phirst-impressions-logo-3-500.png"
              className="header-img-impressions"
            ></img>
            <p>
              Welcome to PHirst Impressions, a residential brand that offers
              living experiences for you to leave a lasting mark. Our townhouse
              units, called Calista, are available in End, Mid, and Pair units,
              providing you with various options to choose from to suit your
              needs.
            </p>
          </div>
          <div className="color-overlay"></div>
        </div>
        <div className="properties">
          <h2>Choose Your Property</h2>
          <div className="flip-card-container">
            <FlipCardComponent
              title="Calista End"
              cardID="calistaEnd"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-end-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-end-back.JPG"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 60.5sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-end"
              cardKey={1}
              color="#4e2e61"
            />
            <FlipCardComponent
              title="Calista Mid"
              cardID="calistaMid"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-mid-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-mid-back.JPG"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 44sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-mid"
              cardKey={2}
              color="#4e2e61"
            />
            <FlipCardComponent
              title="Calista Pair"
              cardID="calistaPair"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-pair-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-pair-back.jpg"
              specs={[
                "Floor Area: 80sqm",
                "Typical Lot Area: 104.5sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-pair"
              cardKey={3}
              color="#4e2e61"
            />
          </div>
          <div className="impressionsScene">
            <ImpressionsCardComponent
              title="Calista End"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-end-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-end-back.JPG"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 60.5sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-end"
            />
            <ImpressionsCardComponent
              title="Calista Mid"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-mid-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-mid-back.JPG"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 44sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-mid"
            />
            <ImpressionsCardComponent
              title="Calista Pair"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/calista-pair-front.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-calista-pair-back.jpg"
              specs={[
                "Floor Area: 80sqm",
                "Typical Lot Area: 104.5sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://phirstcentrale.com.ph/calista-pair"
            />
          </div>
        </div>
        <div className="impresionPillar">
          <div className="impresionPillar-content">
            <p>
              Fall in love with PHirst’s beautifully{" "}
              <b className="impresionWrapper-pillar">
                <motion.span
                  className="impresionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  Complete
                </motion.span>

                <b className="impresionMain-text">Complete</b>
              </b>{" "}
              homes.
            </p>
            <p>
              Where engaging &{" "}
              <b className="impresionWrapper-pillar">
                <motion.span
                  className="impresionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
                  viewport={{ once: true }}
                >
                  Conceptive
                </motion.span>

                <b className="impresionMain-text">Conceptive </b>
              </b>{" "}
              amenities are brought to life.
            </p>
            <p>
              Where everyone is{" "}
              <b className="impresionWrapper-pillar">
                <motion.span
                  className="impresionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 2 }}
                  viewport={{ once: true }}
                >
                  Connected
                </motion.span>

                <b className="impresionMain-text">Connected </b>
              </b>{" "}
              to life’s essentials.
            </p>
            <p>
              Where owning your first home is a{" "}
              <b className="impresionWrapper-pillar">
                <motion.span
                  className="impresionAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 3 }}
                  viewport={{ once: true }}
                >
                  Convenient
                </motion.span>

                <b className="impresionMain-text">Convenient</b>
              </b>{" "}
              and guided journey.
            </p>
          </div>
        </div>
        <div className="video-embed">
          <a
            className="impresionBtn"
            href="https://www.phirstcentrale.com.ph/phirst-impressions"
            target="_blank"
          >
            <span class="impresion__icon-wrapper">
              <svg
                width="10"
                class="impresion__icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>

              <svg
                class="impresion__icon-svg impresion__icon-svg--copy"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>
            </span>
            Visit the Website to learn more
          </a>
        </div>
        <ContactFormTemplate email="phirstimpressions@gmail.com" />
        <Footer />
      </div>
    </>
  );
};
const ImpressionsCardComponent = (props) => {
  return (
    <div className="card">
      <div className="card__face card__face--front">
        <div className="card-title">{props.title}</div>
        <div className="img-gradient"></div>
        <img src={props.imgFront} />
      </div>
      <div className="card__face card__face--back">
        <div className="impressionsSpecification">
          <b className="">Specifications</b>
          {props.specs.map((item, key) => {
            return <div key={key}>{item}</div>;
          })}
          <a href={props.link} target="_blank">
            See More
          </a>
        </div>
        <img src={props.imgBack} />
      </div>
    </div>
  );
};
export default ImpressionsPage;
