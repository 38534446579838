import React, { useEffect, useState } from "react";

import "../css/sights.css";
import "../css/cardComponent.css";
import Footer from "../../partials/components/Footer";
import LoadScreen from "../../others/components/loadScreen";
import { motion } from "framer-motion";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackBtn from "../../others/components/backBtn";
import LandscapeFlipCardComponent from "../../../components/LandscapeFlipCard/LandscapeFlipCard";

const SightsPage = () => {
  const bannerImg =
    "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/marketing-tent-main-bg.jpg";
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);

  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="content sights">
        <div
          className="banner"
          style={{ backgroundImage: 'url("' + bannerImg + '"' }}
        >
          <div className="text-content">
            {/* <h1>PHirst <span>Sights</span></h1> */}
            {/* <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/sights-logo-v1.png" className="header-img-sights"></img> */}
            <img
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/sights-logo-v2-500.png"
              className="header-img-sights"
            ></img>
            <p>
              Welcome to PHirst Sights, your ultimate solution for affordable
              and stylish housing in the Philippines. Our brand specializes in
              economic horizontal homes, featuring both the chic loft-style
              Alora Series rowhouses and the cozy Amani Series townhouses.
            </p>
          </div>
          <div className="color-overlay"></div>
        </div>
        <div className="properties">
          <h2>Choose Your Property</h2>
          <div className="flip-card-container">
            <LandscapeFlipCardComponent
              title="Amani Series"
              cardID="amaniSeries"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-amani.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-amani.jpg"
              specs={[
                "Typical Lot Area (End): 55 sqm",
                "Typical Lot Area (Inner): 40 sqm",
                "Floor Area: 36 sqm",
                "2 Bedrooms",
                "1 Toilet and Bath",
                "Gate and Fence",
                "Provision for 1 Carport(Compact Car)",
                "Expandable",
              ]}
              link="https://phirstsights.com.ph/amani-series"
              cardKey={1}
              color="#e5a724"
            />
            <LandscapeFlipCardComponent
              title="Alora Series"
              cardID="aloraSeries"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-alora.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-alora.jpg"
              specs={[
                "Typical Lot Area (Inner): 41 sqm",
                "Typical Lot Area (End): 56 sqm",
                "Floor Area: 30 sqm",
                "1 Loft (Provision for 1 Bedroom)",
                "1 Toilet and Bath",
                "Expandable",
              ]}
              link="https://phirstsights.com.ph/alora-series"
              cardKey={2}
              color="#e5a724"
            />
          </div>
          <div className="sightsScene">
            <SightsCardComponent
              title="Amani Series"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-amani.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-amani.jpg"
              specs={[
                "Typical Lot Area (End): 55 sqm",
                "Typical Lot Area (Inner): 40 sqm",
                "Floor Area: 36 sqm",
                "2 Bedrooms",
                "1 Toilet and Bath",
                "Gate and Fence",
                "Provision for 1 Carport(Compact Car)",
                "Expandable",
              ]}
              link="https://phirstsights.com.ph/amani-series"
            />
            <SightsCardComponent
              title="Alora Series"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-alora.jpg"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/card-bg-alora.jpg"
              specs={[
                "Typical Lot Area (Inner): 41 sqm",
                "Typical Lot Area (End): 56 sqm",
                "Floor Area: 30 sqm",
                "1 Loft (Provision for 1 Bedroom)",
                "1 Toilet and Bath",
                "Expandable",
              ]}
              link="https://phirstsights.com.ph/alora-series"
            />
          </div>
        </div>
        <div className="sightsPillar">
          <div className="sightsPillar-content">
            <p>
              We bring you quality and{" "}
              <b className="sightsWrapper-pillar">
                <motion.span
                  className="sightsAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  Attractive
                </motion.span>

                <b className="sightsMain-text">Attractive </b>
              </b>{" "}
              homes.
            </p>
            <p>
              That enable you to enjoy a healthy and{" "}
              <b className="sightsWrapper-pillar">
                <motion.span
                  className="sightsAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
                  viewport={{ once: true }}
                >
                  Active
                </motion.span>

                <b className="sightsMain-text">Active</b>
              </b>{" "}
              lifestyle.
            </p>
            <p>
              Where essentials are{" "}
              <b className="sightsWrapper-pillar">
                <motion.span
                  className="sightsAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 2 }}
                  viewport={{ once: true }}
                >
                  Accessible
                </motion.span>

                <b className="sightsMain-text">Accessible</b>
              </b>{" "}
              anytime, anywhere.
            </p>
            <p>
              And where the community feels good and{" "}
              <b className="sightsWrapper-pillar">
                <motion.span
                  className="sightsAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 3 }}
                  viewport={{ once: true }}
                >
                  Alive
                </motion.span>

                <b className="sightsMain-text">Alive</b>
              </b>{" "}
              .
            </p>
          </div>
        </div>
        <div className="video-embed">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/f2v5H2U3PiQ?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <a
            className="sightsBtn"
            href="https://www.phirstsights.com.ph"
            target="_blank"
          >
            <span class="sights__icon-wrapper">
              <svg
                width="10"
                class="sights__icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>

              <svg
                class="sights__icon-svg  sights__icon-svg--copy"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>
            </span>
            Visit the Website to learn more
          </a>
        </div>
        <ContactFormTemplate email="phirstsights@gmail.com" />
        <Footer />
      </div>
    </>
  );
};
const SightsCardComponent = (props) => {
  return (
    <div className="card">
      <div className="card__face card__face--front">
        <div className="card-title">{props.title}</div>
        <div className="img-gradient"></div>
        <img src={props.imgFront} />
      </div>
      <div className="card__face card__face--back">
        <div className="sightsSpecification">
          <b className="">Specifications</b>
          {props.specs.map((item, key) => {
            return <div key={key}>{item}</div>;
          })}
          <a href={props.link} target="_blank">
            See More
          </a>
        </div>
        <img src={props.imgBack} />
      </div>
    </div>
  );
};
export default SightsPage;
