import { useState, useEffect } from "react";
import "./LandScapeFlipCard.css";

const LandscapeFlipCardComponent = (props) => {
  const [isCardFlipped, setCardState] = useState();
  const [isFirstCard, setFirstCard] = useState(false);

  useEffect(() => {
    if (props.cardKey == 1) {
      setFirstCard(true);
    }
  }, []);

  useEffect(() => {
    if (isCardFlipped != null) {
      let card = document.getElementById(props.cardID);
      if (isCardFlipped) {
        card.classList.add("landscape-show");
      } else {
        card.classList.remove("landscape-show");
      }
    }
  }, [isCardFlipped]);

  return (
    <>
      <div
        className="landscape-flip-card"
        id={props.cardID}
        key={props.cardKey}
        onClick={() => {
          setCardState((prevState) => !prevState);
        }}
      >
        <div className="landscape-flip-card-inner">
          <div className="landscape-flip-card-front">
            <div className="landscape-flip-text-content">
              <div className="landscape-card-title">{props.title}</div>
            </div>
            <div className="landscape-color-overlay"></div>
            <div
              className="landscape-bg-overlay"
              style={{ backgroundImage: 'url("' + props.imgFront + '")' }}
            ></div>
          </div>
          <div className="landscape-flip-card-back">
            <div className="landscape-flip-text-content">
              <h5 style={{ color: props.color }}>Specifications</h5>
              {props.specs.map((item, key) => {
                return <div key={key}>{item}</div>;
              })}
              <a
                href={props.link}
                target="_blank"
                style={{ color: props.color }}
              >
                See More
              </a>
            </div>
            <div className="landscape-color-overlay-back"></div>
            <div
              className="landscape-bg-overlay"
              style={{ backgroundImage: 'url("' + props.imgBack + '")' }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LandscapeFlipCardComponent;
