import React, { useEffect, useState } from "react";

import { Centrale, Editions, PhirstParkHomes,Sights, Impressions, Residences, Township } from "./content";

// import { Data } from '../assets/files/modalContents'

const Modal = (props) => {


    useEffect(() => {
    }, [])


    const Render = (title) => {
        switch (title) {
            case "Centrale":
                return <Centrale {...props} />
                break;
            case "Editions":
                return <Editions {...props} />
                break;
            case "PhirstParkHomes":
                return <PhirstParkHomes {...props} />
                break;
            case "Sights":
                return <Sights {...props} />
                break;
            case "Impressions":
                return <Impressions {...props} />
                break;
            case "Residences":
                return <Residences {...props} />
                break;
            case "Township":
                return <Township {...props} />
                break;
            // default:
            //     break;
        }
    }
    
    return (
        <>
            { Render(props.title) }
            <div className="dark-screen" onClick={ props.hideModal }></div>
        </>
    )
    
}

export default Modal
