export const coords = [

    {
        id: "Township",
        title: "Township",
        shape: "poly",
        name: "township",
        coords: [2,609,2,622,152,656,375,613,606,672,606,690,491,695,491,713,509,738,511,774,513,823,561,842,638,853,785,853,832,844,1015,805,1015,713,1248,715,1248,751,1434,747,1434,616,1452,613,1450,600,1411,582,1242,584,1242,577,1171,555,1151,555,1153,604,1049,604,1049,568,1067,568,1067,555,968,530,771,532,771,541,773,543,796,545,796,595,701,582,669,584,667,591,556,584,529,584,529,591,425,586,393,586,393,593,287,588,260,586,260,607,0,607],
        fillColor: "rgba(55, 145, 68, .5)",
    },

    {
        id: "Residences",
        title: "Residences",
        shape: "poly",
        name: "residences",
        coords: [1469,626,1472,692,2516,684,2516,542,2460,520,2449,520,2449,460,2428,444,2349,448,2354,493,2356,515,2358,540,2300,543,2298,496,2309,475,2293,461,2212,457,2194,470,2198,511,2201,558,2129,560,2111,540,2111,497,2111,482,2093,468,1948,475,1948,484,1960,484,1957,515,1917,515,1917,572,1899,565,1901,533,1885,506,1777,513,1784,587,1763,587,1760,528,1737,515,1656,524,1660,594,1644,596,1642,551,1613,529,1550,532,1548,611,1524,618,1473,616,1469,631],
        fillColor: "rgba(55, 145, 68, .5)",
    },

]
