import React, { useEffect, useRef, useState } from "react";

import "../css/about.css";
import "../css/section-intro.css";
import "../css/section-standout.css";
import "../css/section-dream.css";
import "../css/who-we-are.css";

import Footer from "../../partials/components/Footer";

import LoadScreen from "../../others/components/loadScreen";

import BackBtn from "../../others/components/backBtn";

const AboutUs = () => {
  const [cardToggle, setCardState] = useState(0);
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);

  const bannerAnim = (entries, observer) => {
    let banner = document.getElementById("banner");
    entries.forEach((item) => {
      if (item.isIntersecting) {
        banner.classList.add("show");
      } else {
        banner.classList.remove("show");
      }
    });
  };

  const valuesAnim = (entries, observer) => {
    // console.log(entries)
    // let valuesCont = document.getElementById('valuesCont')
    entries.forEach((item) => {
      if (item.isIntersecting) {
        item.target.classList.add("show");
      } else {
        item.target.classList.remove("show");
      }
    });
  };

  useEffect(() => {
    const bannerOptions = {
      rootMargin: "200px",
      threshold: 0.6,
    };

    const bannerObserver = new IntersectionObserver(bannerAnim, bannerOptions);
    let banner = document.getElementById("banner");
    bannerObserver.observe(banner);

    let introSection = document.getElementById("sectionIntro");
    introSection.style.backgroundImage =
      "url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/editions-fam-1.jpg')";

    const valuesOptions = {
      rootMargin: "200px",
      threshold: 0.6,
    };

    const valuesObserver = new IntersectionObserver(valuesAnim, valuesOptions);
    let values = document.querySelectorAll(".values-cont > div");

    values.forEach((entry) => {
      // console.log(entry)
      valuesObserver.observe(entry);

      return () => {
        valuesObserver.unobserve(entry);
      };
    });

    return () => {
      bannerObserver.unobserve(banner);
    };
  }, []);

  useEffect(() => {
    let bgHolder,
      introSection = document.getElementById("sectionIntro");

    if (cardToggle != null) {
      switch (cardToggle) {
        case 0:
          bgHolder =
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/pphi-fam-1.jpg";
          break;
        case 1:
          bgHolder =
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/editions-fam-1.jpg";
          break;
        case 2:
          bgHolder =
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/sights-fam-1.jpg";
          break;
        case 3:
          bgHolder =
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/centrale-fam-1.jpg";
          break;
        case 4:
          bgHolder =
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/centrale-fam-2.jpg";
          break;
        default:
          break;
      }

      introSection.style.backgroundImage = "url('" + bgHolder + "')";
    }
  }, [cardToggle]);

  const backSlide = () => {
    if (cardToggle != 0) {
      let cards = document.querySelectorAll(".card-slide.desktop .card");
      setCardState((prevState) => prevState - 1);
      cards[cardToggle - 1].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const nextSlide = () => {
    if (cardToggle != 4) {
      let cards = document.querySelectorAll(".card-slide.desktop .card");
      setCardState((prevState) => prevState + 1);
      cards[cardToggle + 1].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  return (
    <>
      {loadState ? <LoadScreen /> : ""}
      <div className="content about">
        <BackBtn />
        <main className="banner" id="banner">
          <h1>
            The <span>Home</span> <br /> Of Many <span>Firsts</span>
          </h1>
          <img
            className="banner-img img-1"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-1.png"
            alt=""
            draggable={false}
          />
          <img
            className="banner-img img-2"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-2.png"
            alt=""
            draggable={false}
          />
          <img
            className="banner-img img-3"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-3.png"
            alt=""
            draggable={false}
          />
          <img
            className="banner-img img-4"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-4.png"
            alt=""
            draggable={false}
          />
          <img
            className="banner-img img-5"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-5.png"
            alt=""
            draggable={false}
          />
          <img
            className="banner-img img-6"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/img-6.png"
            alt=""
            draggable={false}
          />
        </main>

        <div className="section intro-mobile">
          <div className="text-content">
            <div>
              <h2>PHirst: Providing Homes for Everyone</h2>
              <p>
                PHirst is one of the fastest growing real estate brands that
                believes in providing quality homes for everyone. It is a new
                chapter, a new era for developers, and it has expanded its
                portfolio to offer more options to choose from. PHirst has five
                unique brands under its umbrella, each with its own identity and
                catering to various market segments.
              </p>
            </div>
          </div>
          <div className="cards-container-mobile">
            <div className="card-slide-mobile">
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-impression-1.jpg"
                cardTitle="PHirst Park Homes"
                cardDesc="The first of the five brands, offers a range of quality residential units including townhouses named Calista, single-attached units named Unna, and bungalow units named Brenna."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-pphi.png"
                link="https://www.phirstparkhomes.com/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-editions-1.jpg"
                cardTitle={
                  <>
                    PHirst <br />
                    <span>Editions</span>
                  </>
                }
                cardDesc="Offers exquisite single attached homes with more features and space.You can choose from four options, namely Cartland 54, Charles 70, Christie 90, and Corin 120, each of which corresponds to its respective floor area."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-editions-white.png"
                link="https://www.phirsteditions.com.ph/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-impression-1.jpg"
                cardTitle={
                  <>
                    PHirst <br /> <span>Impressions</span>
                  </>
                }
                cardDesc="One of the residential brands located in the heart of PHirst Centrale. It offers townhouse units called Calista, which are available in End, Mid, and Pair units for you to choose from."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-impressions-white.png"
                link="https://phirstcentrale.com.ph/phirst-impressions"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-amani-1.jpg"
                cardTitle={
                  <>
                    PHirst <span>Sights</span>
                  </>
                }
                cardDesc="The ultimate solution for affordable and stylish housing! Our brand specializes in economic horizontal homes, featuring both the chic loft-style Alora Series rowhouses and the cozy Amani Series townhouses."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-sights-white.png"
                link="https://phirstsights.com.ph/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-centrale-1.jpg"
                cardTitle="PHirst Centrale"
                cardDesc="A dynamic township anchored on three branding pillars: DWELL features PHirst Impressions (townhouse) and PHirst Editions (premium single attached houses); CREATE boasts The Fairgrounds, a commercial and institutional area; and THRIVE showcases The Boroughs, a vibrant retail destination."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-centrale-white.png"
                link="https://phirstcentrale.com.ph/"
              />
            </div>
          </div>
        </div>

        <div className="section intro" id="sectionIntro">
          <div className="text-content">
            <div>
              <h2>
                PHirst: Providing Homes <br /> for Everyone
              </h2>
              <p>
                PHirst, one of the fastest growing real estate brands in the
                Philippines, is committed to delivering quality homes accessible
                to every Filipino. PHirst ensures that every aspiring homeowner
                will have security, stability, and enjoyment within and beyond
                the homes that it offers. Representing a new chapter in real
                estate development, it offers an expanded portfolio to cater to
                diverse market preferences. PHirst offers five distinct brands,
                each tailored to specific market segments, ensuring a
                comprehensive range of options for homebuyers.
              </p>
            </div>
          </div>
          <div className="cards-container">
            <div className="card-slide desktop">
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-impression-1.jpg"
                cardTitle="PHirst Park Homes"
                cardDesc="The first of the five brands, offers a range of quality residential units including townhouses named Calista, single-attached units named Unna, and bungalow units named Brenna."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-pphi.png"
                link="https://www.phirstparkhomes.com/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-editions-1.jpg"
                cardTitle={
                  <>
                    PHirst <br />
                    <span>Editions</span>
                  </>
                }
                cardDesc="Offers exquisite single attached homes with more features and space.You can choose from four options, namely Cartland 54, Charles 70, Christie 90, and Corin 120, each of which corresponds to its respective floor area."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-editions-white.png"
                link="https://www.phirsteditions.com.ph/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-impression-1.jpg"
                cardTitle={
                  <>
                    PHirst <br /> <span>Impressions</span>
                  </>
                }
                cardDesc="One of the residential brands that offers living experiences for you to leave a lasting mark. It offers townhouse units called Calista, available in End, Mid, and Pair units for you to choose from."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-impressions-white.png"
                link="https://phirstcentrale.com.ph/phirst-impressions"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-amani-1.jpg"
                cardTitle={
                  <>
                    PHirst <span>Sights</span>
                  </>
                }
                cardDesc="The ultimate solution for affordable and stylish housing! Our brand specializes in economic horizontal homes, featuring both the chic loft-style Alora Series rowhouses and the cozy Amani Series townhouses."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-sights-white.png"
                link="https://phirstsights.com.ph/"
              />
              <CardComponent
                cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-bg-centrale-1.jpg"
                cardTitle="PHirst Centrale"
                cardDesc="A dynamic township anchored on three branding pillars: DWELL features PHirst Impressions (townhouse) and PHirst Editions (premium single attached houses); CREATE boasts The Fairgrounds, a commercial and institutional area; and THRIVE showcases The Boroughs, a vibrant retail destination."
                cardLogo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/logo-centrale-white.png"
                link="https://phirstcentrale.com.ph/"
              />
              <div className="phantom-card"></div>
            </div>
            <div className="card-controls">
              <img
                className="btn-left"
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-left-btn-v1.png"
                alt=""
                onClick={backSlide}
              />
              <img
                className="btn-right"
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-right-btn-v1.png"
                alt=""
                onClick={nextSlide}
              />
              {/* <img className="control-bg" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/slide-control-bg.png" alt="" /> */}
            </div>
            <div className="card-color-overlay"></div>
          </div>
        </div>

        <div className="section stand-out">
          <div className="text-content">
            <h2>What Makes PHirst Stand Out?</h2>
          </div>
          <div className="cards">
            <CardComponentV2
              cardIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="card-icon"
                >
                  <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                  <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
              }
              cardTitle="Developing Fully Finished Homes"
              cardDesc="PHirst offers fully finished homes, which means that everything is ready for you to move in. You don't have to worry about painting walls or flooring."
            />
            <CardComponentV2
              cardIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="card-icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              cardTitle="Creating Unique Amenities"
              cardDesc="PHirst offers amenities that go beyond the basics. Each subdivision has its own specific theme for amenities, making it enjoyable for both young and young at heart."
            />
            <CardComponentV2
              cardIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="card-icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z"
                    clip-rule="evenodd"
                  />
                  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                </svg>
              }
              cardTitle="Keeping You Connected"
              cardDesc="PHirst aims to keep everyone connected to life's essentials by offering shuttle services to transport hubs."
            />
            <CardComponentV2
              cardIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="card-icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              cardTitle="Guiding you throughout your home-buying journey"
              cardDesc="PHirst guides you throughout your home-buying journey, making it stress-free and enjoyable."
            />
          </div>
        </div>

        <div className="section dream">
          <div className="text-content">
            <h2>Make Your Dream Home a Reality with PHirst</h2>
            <p>
              PHirst is not just a brand; it's a lifestyle. It provides
              unforgettable firsts for families to enjoy. Imagine living in a
              PHirst home where everything is hassle-free, and you have unique
              amenities at your disposal.{" "}
            </p>
            <p>
              Whether you're a first-time buyer or an experienced one, PHirst
              makes sure that you're taken care of every step of the way. So
              what are you waiting for? Make your dream home a reality with
              PHirst, the home of many firsts.
            </p>
          </div>
          <img
            className="woman-img"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/section-dream/fam-img-about-1.png"
            alt=""
            draggable={false}
          />
          <img
            className="circle-img"
            src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/section-dream/section-dream-circle.png"
            alt=""
            draggable={false}
          />
        </div>

        <div className="section who-we-are">
          <div className="vision">
            <h2>
              Our <span>Vision</span>
            </h2>
            <small className="aboutSubtitle">who we want to be</small>
            <p>
              <em>We</em> are the <em>FOREMOST</em> developer and provider of{" "}
              <em>FIRST HOMES</em>.
            </p>
          </div>
          <div className="mission">
            <h2>
              Our <span>Mission</span>
            </h2>
            <small className="aboutSubtitle">Our reason for being</small>

            <p>
              We work with purpose, creating a Convenient access to living in
              Complete, Conceptive, Connected communities, situated in major
              areas across the Philippines where there’s strong middle-class
              market gainfully engaged locally and/or abroad, a thriving local
              economy, and infrastructure, developments are built-in, underway
              or in the pipeline.{" "}
            </p>
            <p>
              We value our Shareholders by delivering healthy financial returns
              on their investment, and by continuously enhancing the own brand
              equity, we add value and integrity to our Shareholders’ Brands.
            </p>
            <p>
              We treasure talented individuals, the synergy they create when
              working as a solid, committed team, and the drive for innovation,
              pursuing fresh ideas to break the status quo.
            </p>
            <p>
              We work with the most trusted names in real estate business,
              forging alliances with other stakeholders involving mutually
              beneficial ventures, jointly contribute to addressing current
              issues, shaping future regulatory or legislative agenda, and leave
              an indelible mark on the housing industry and society.
            </p>
          </div>
          <div className="values" id="valuesCont">
            <h2>
              Our <span>Values</span>
            </h2>
            <div className="values-cont">
              <div>
                <img
                  className="values-logo"
                  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-integrity-v1.png"
                  alt=""
                />
                <h4>Integrity</h4>
                <ul>
                  <li>To deliver on promise</li>
                  <li>To do the right thing</li>
                  <li>
                    To be upstanding <br /> corporate citizens
                  </li>
                </ul>
              </div>
              <div>
                <img
                  className="values-logo"
                  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-collaboration-v1.png"
                  alt=""
                />
                <h4>Collaboration</h4>
                <ul>
                  <li>To be considerate and mindful</li>
                  <li>
                    To be open to ideas and <br /> learn from others
                  </li>
                  <li>To understand the business and impact of work</li>
                </ul>
              </div>
              <div>
                <img
                  className="values-logo"
                  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-innovation-creativity-v1.png"
                  alt=""
                />
                <h4>Innovation & Creativity</h4>
                <ul>
                  <li>To evolve and innovate</li>
                  <li>To practice smart problem solving</li>
                  <li>To stay progressive and relevant to the times</li>
                </ul>
              </div>
              <div>
                <img
                  className="values-logo"
                  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-social-impact-v1.png"
                  alt=""
                />
                <h4>Social Impact</h4>
                <ul>
                  <li>To uphold corporate social responsibility</li>
                </ul>
              </div>
              <div>
                <img
                  className="values-logo"
                  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-service-excellence-v1.png"
                  alt=""
                />
                <h4>Service Excellence</h4>
                <ul>
                  <li>To be customer-focused externally and internally</li>
                  <li>To put time at the core of excellence in execution</li>
                  <li>To be proactive and accountable for one's action</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const CardComponent = (props) => {
  useEffect(() => {
    let introSection = document.getElementById("sectionIntro");
    const introOptions = {
      threshold: 0.75,
    };
    const introObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        introSection.classList.add("show");
        cardObserve(true);
      } else {
        introSection.classList.remove("show");
        cardObserve(false);
      }
    }, introOptions);

    introObserver.observe(introSection);
  }, []);

  const cardObserve = (state) => {
    const cardOptions = {
      threshold: 1,
      rootMargin: "0px 0px 0px 0px",
    };
    const cardObserver = new IntersectionObserver(cardShuffle, cardOptions);

    let cards = document.querySelectorAll(".card-slide .card");

    if (state) {
      cards.forEach((card) => {
        cardObserver.observe(card);
      });
    } else {
      cards.forEach((card) => {
        cardObserver.unobserve(card);
      });
    }
  };

  const cardShuffle = (entries, observer) => {
    if (entries[0].isIntersecting) {
      entries[0].target.classList.add("show");
    } else {
      entries[0].target.classList.remove("show");
    }
  };

  return (
    <>
      <div
        className="card hide"
        style={{ backgroundImage: 'url("' + props.cardBg + '")' }}
      >
        <div className="card-content">
          <img className="card-logo" src={props.cardLogo} alt="" />
          <h4>{props.cardTitle}</h4>
          <p>{props.cardDesc}</p>
          <a className="learn-more-btn" href={props.link} target="_blank">
            Learn More
          </a>
        </div>
        <div className="color-overlay"></div>
      </div>
    </>
  );
};

const CardComponentV2 = (props) => {
  return (
    <>
      <div className="card">
        <div className="text-content">
          <div>{props.cardIcon}</div>
          <h4>{props.cardTitle}</h4>
          <p>{props.cardDesc}</p>
        </div>
        <div className="color-overlay"></div>
      </div>
    </>
  );
};

export default AboutUs;
