import React, { useEffect, useState } from "react";

import '../css/footer.css'

import { useLocation, NavLink  } from "react-router-dom";

const Footer = () => {
    
    const [ isCareers, setIsCareersState ] = useState()
    let location = useLocation()

    useEffect(() => {
        if (location.pathname != null) {
            if (location.pathname === '/phirst-careers') {
                setIsCareersState(true)
            } else {
                setIsCareersState(false)
            }
        }
    }, [])

    return (
        <>
            { isCareers ? 
                <FooterTemplateV2/> : 
                <FooterTemplateV1/>
            }
        </>
    )
}

// Default
const FooterTemplateV1 = () => {
    return (
        <>
            <footer>
                <div className="section-cont">
                    <div className="footer-section1">
                        <img className="phirst-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/phirst-logo-white.png" alt="" />
                        <p>PHirst is one of the fastest growing real estate brands that believes in providing quality homes for everyone. It is a new chapter, a new era for developers, and it has expanded its portfolio to offer more options to choose from. PHirst has five unique brands under its umbrella, each with its own identity and catering to various market segments.</p>
                    </div>

                    <div className="footer-section2">
                        <div>
                            <h4>Brands</h4>
                            <ul>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Park Homes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Editions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Impressions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Sights
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Centrale
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>Careers</h4>
                            <ul>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Careers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        Explore Career Opportunities
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="footer-section3">    
                        <h4>Follow our Socials</h4>
                            <div className="socials-cont">
                                <a href = 'https://www.facebook.com/PHirstParkHomesCareers' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-fb-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.instagram.com/phirst.ph/' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-ig-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.tiktok.com/@phirst_ph' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-tiktok-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.youtube.com/@PHirst' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-yt-v1.png" alt="" />
                                </a>
                            </div>
                    </div>

                </div>

                <div className="privacy-policy">
                    <p>©2023 Century PHirst Corporation. All Rights Reserved | <NavLink to='/privacy-policy'>Privacy Policy</NavLink></p>
                </div>
            </footer>
        </>
    )
}

// Careers
const FooterTemplateV2 = () => {
    return (
        <>
            <footer className="footer-v2">
                <div className="section-cont">
                    <div className="footer-section1">
                        <img className="phirst-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/phirst-logo-white.png" alt="" />
                        <p>PHirst Careers, the recruiting pillar of PHirst, has been created to enhance awareness regarding employer branding and job opportunities. The brand aims for every job seeker in the market to perceive PHirst as the foremost employer by promoting a conducive environment, growth-oriented challenges, and work-life harmony. The main purpose of PHirst Careers is to serve as a means for both nation-builders and the new generation to attain their ideal workplace, offering long-term career prospects.</p>
                    </div>

                    <div className="footer-section2">
                        <div>
                            <h4>Contact Us</h4>
                            <ul>
                                <li>
                                    21st Floor, Pacific Star Building, Gil Puyat cor. Makati Ave., Makati City, Philippines 1200
                                </li>
                                <li>
                                    careers@phirstparkhomes.com
                                </li>
                                <li>                     
                                    Tel No: 7935076
                                </li>
                                <li>
                                    PHirst Park Homes, Inc.
                                </li>
                                <li>Century PHirst Corporation</li>
                            </ul>
                        </div>
                        {/* <div>
                            <h4>Careers</h4>
                            <ul>
                                <li>
                                    <NavLink to='/'>
                                        PHirst Careers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>
                                        Explore Career Opportunities
                                    </NavLink>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                
                    <div className="footer-section3">    
                        <h4>Follow our Socials</h4>
                            <div className="socials-cont">
                                <a href = 'https://www.facebook.com/PHirstParkHomesCareers' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-fb-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.instagram.com/phirst.ph/' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-ig-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.tiktok.com/@phirst_ph' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-tiktok-v1.png" alt="" />
                                </a>
                                <a href = 'https://www.youtube.com/@PHirst' target="_blank">
                                    <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-yt-v1.png" alt="" />
                                </a>
                            </div>
                    </div>

                </div>

                <div className="privacy-policy">
                    <p>©2023 Century PHirst Corporation. All Rights Reserved | <NavLink to='/privacy-policy'>Privacy Policy</NavLink></p>
                </div>
            </footer>
        </>
    )
}

export default Footer
