import React, { useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import '../css/jobs.css'

import { useNavigate } from 'react-router-dom'

import LoadScreen from "../../others/components/loadScreen";
import { useRef } from "react";

import JobForm from "./form";

import BackBtn from "../../others/components/backBtn";

const JobListing = () => {
    const [ loadState, setLoadState ] = useState(false)
    const iframeRef = useRef(null)

    const [ careerData, setCareerData ] = useState([])

    const source = axios.CancelToken.source()

    const [ isModalShown, setModalState ] = useState(false)
    const [ modalId, setModalId ] = useState()

    useEffect(() => {

        getAllCareers(source.token)

        return () => {
            source.cancel('Requested Cancel by cleanup')
        }
    }, [])

    const getAllCareers = () => {
        try {
            // const hostname = window.location.protocol + '//' + window.location.hostname
            // const url = hostname + ':3001/api/retrieve/careers/list'
        const envDomain = process.env.REACT_APP_DOMAIN_NAME
        const envPort = process.env.REACT_APP_BACKEND_PORT
        // const endpoint = envDomain + ':' + envPort + '/api/retrieve/careers/list'
        const endpoint = envDomain + '/api/retrieve/careers/list'
            axios.get(endpoint, {cancelToken: source.token})
            .then(response => {
                // Handle the response data
                setCareerData(response.data)
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
        } catch (error) {
            if (axios.isCancel(error)) {
              console.log('Request canceled', error.message);
            } else {
              console.error('Error fetching data:', error);
            }
        }
    }

    const getCurrentUrl = () => {
        const currentUrl = iframeRef.current.contentWindow.location.href
        return currentUrl
    }

    const goBack = () => {
        iframeRef.current.contentWindow.history.back();
    }

    useEffect(() => {
        setLoadState(true)
    }, [])
    

    const showModal = (event, id) => {
        event.preventDefault()
        setModalState(true)
        setModalId(id)
        console.log("clicked, ", isModalShown)
    }
    const hideModal = () => {
        setModalState(false)
        console.log(isModalShown)
    }
    const closeModal = () => {
        setModalState(false)
    }


    return (
        <>
            <BackBtn />
            { loadState ? <LoadScreen/> : ''}
            <div className="content jobs">
                <div className="career-container">
                    <div className="career-list">
                        {
                            careerData.map((item, index) => {
                                return <CareerItem key={index} { ...item} showModal={showModal} hideModal={hideModal}/>
                            })
                        }
                    </div>
                </div>
                {/* <img className="back-btn-v1" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/back-btn-v1.png" alt="" onClick={ goBack } />
                <div className="iframe-container">
                    <iframe ref={iframeRef} src="https://wordpress-980726-3489233.cloudwaysapps.com/index.php/job-openings/" height={400} width={1000} />
                </div> */}
            </div>
            <JobForm modalState={isModalShown} handleClick={hideModal} jobId={modalId} closeModal={closeModal}/>
        </>
    )
}

const CareerItem = (props) => {

    const [ cResponsibilities, setResponsibilities ] = useState([])
    const [ cRequirements, setRequirements ] = useState([])
    const [ cSpecs, setSpecs ] = useState([])

    const [ isRespNotEmpty, setRespState ] = useState(false) // Responsibilities
    const [ isReqNotEmpty, setReqState ] = useState(false) // Requirements
    const [ isSpecsNotEmpty, setSpecsState ] = useState(false) // Specs

    const navigate = useNavigate()

    const [ dataHolder, setDataHolder ] = useState({})

    const source = axios.CancelToken.source()
    

    useEffect(() => {
        if (props.length != 0) {
            setDataHolder(props)
        }
        getCareerSpecs()
        const delay = 1000
        // const timer = setTimeout(() => {
        //     getCareerResponsibilities()
        // }, delay);

        return () => {
            // clearTimeout(timer)
            source.cancel('Requested Cancel by cleanup')
        }
    }, [])

    useEffect(() => {
        if (Object.keys(dataHolder) !== 0) {
            // getCareerRequirements(source.token)
        }
    }, [dataHolder])

    useEffect(() => {
        if (cResponsibilities.length !== 0) {
            setRespState(true)
            console.log(cResponsibilities)
        }
    }, [cResponsibilities])
    
    useEffect(() => {
        if (cRequirements.length !== 0) {
            setReqState(true)
        }
    }, [cRequirements])
    
    const getCareerSpecs = async () => {
        try {
            const envDomain = process.env.REACT_APP_DOMAIN_NAME
            const endpoint = envDomain + '/api/retrieve/careers/specs/'
            await axios.get(endpoint + props.job_id, { cancelToken: source.token})
                .then(response => {
                    if (response.data.length !== 0) {
                        setSpecs(response.data)
                        setSpecsState(true)
                        console.log('Success')
                    } else {
                        setSpecsState(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } catch(err) {
            console.log("Career specs retrieval error: ", err)
        }
    }

    // Get responsibilities per career
    const getCareerResponsibilities = async () => {
        try {
            // const hostname = window.location.protocol + '//' + window.location.hostname
            // const url = hostname + ':3001/api/retrieve/careers/resp/'
        const envDomain = process.env.REACT_APP_DOMAIN_NAME
        const envPort = process.env.REACT_APP_BACKEND_PORT
        // const endpoint = envDomain + ':' + envPort + '/api/retrieve/careers/resp/'
        const endpoint = envDomain + '/api/retrieve/careers/resp/'
            await axios.get(endpoint + props.job_id,  { cancelToken: source.token })
            .then(response => {
                // Handle the response data
                if (response.data.length !== 0) {
                    setResponsibilities(response.data)
                    setRespState(true)
                } else {
                    setRespState(false)
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
        } catch (err) {
            console.log(err)
        }
    }

    // Get requirements per career
    // const getCareerRequirements = (cancelToken) => {
    //     try {
    //         const hostname = window.location.protocol + '//' + window.location.hostname
    //         const url = hostname + ':3001/api/career/requirements/'
    //         axios.get(url + dataHolder.job_id, {cancelToken})
    //         .then(response => {
    //             // Handle the response data
    //             if (response.data.length != 0) {
    //                 setRequirements(response.data)
    //             } else {
    //                 setRequirements({})
    //             }
    //         })
    //         .catch(error => {
    //             // Handle any errors
    //             console.error(error);
    //         });
    //     } catch (error) {
    //         if (axios.isCancel(error)) {
    //           console.log('Request canceled', error.message);
    //         } else {
    //           console.error('Error fetching data:', error);
    //         }
    //     }
    // }

    const goToJobPost = (event, jobId) => {
        event.preventDefault()
        navigate('../job', { state: { jobId }})
    }


    return (
        <>
            <div className="career-item">
                <div className="content-left">
                    <div className="text-content">
                        <h5>{ props.job_title }</h5>
                        <p><span>Job Type:</span> { props.job_type }</p>
                        <p><span>Employee Type:</span>{ props.employee_type }</p>
                        <p><span>Location:</span>{ props.job_location }</p>
                        {/* <a href="#" onClick={ (event) => { goToJobPost(event, props.job_id) }}>Read More</a> */}
                        <a href="" onClick={ (event) => { props.showModal(event, props.job_id) }}>Apply now</a>
                    </div>
                </div>
                <div className="content-right">
                    <div className="text-content">
                        <div className="summary">
                            <h5>Specification:</h5>
                            {/* <p>{ props.job_specs}</p> */}
                        </div>
                        <div className="responsibilities">
                            <ul id="respList">
                                { isSpecsNotEmpty ? 
                                    cSpecs.map((item, index) => {
                                        return <li key={index}>{ item.specs_content}</li>
                                    }) : 
                                    <></>
                                }
                            </ul>
                        </div>
                        {/* <div className="responsibilities">
                            <h5>Your Responsibilities:</h5>
                            <ul id="respList">
                                { isRespNotEmpty ? 
                                    cResponsibilities.map((item, index) => {
                                        return <li key={index}>{ item.resp_desc }</li>
                                    }) : 
                                    <></>
                                }
                            </ul>
                        </div> */}
                        {/* <div className="requirements">
                            <h5>Requirements:</h5>
                            <ul id="reqList">
                                { isReqNotEmpty ? 
                                    cRequirements.map((item, index) => {
                                        return <li key={index}>{ item.req_desc }</li>
                                    }) : 
                                    <></>
                                }
                            </ul>
                        </div> */}
                    </div>
                    <div className="section-blur"></div>
                </div>
            </div>
        </>
    )
}


export default JobListing