import React, { useEffect, useState } from "react";

import "../css/pphi.css";
import "../css/cardComponent.css";
import Footer from "../../partials/components/Footer";
import LoadScreen from "../../others/components/loadScreen";
import { motion } from "framer-motion";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackBtn from "../../others/components/backBtn";
import FlipCardComponent from "../../../components/FlipCard/FlipCard";
const PphiPage = () => {
  const bannerImg =
    "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/pphi-banner-v2.JPG";
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);

  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="content pphi">
        <div
          className="banner"
          style={{ backgroundImage: 'url("' + bannerImg + '"' }}
        >
          <div className="text-content">
            <img
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/PPHI-logo-500.png"
              className="header-img-pphi"
            ></img>
            <p>
              Welcome to PHirst Park Homes, the ideal choice for your dream home
              in the Philippines. Our homes are designed with quality, comfort,
              and style in mind, offering a variety of options to suit your
              needs and preferences. From cozy townhouses to spacious
              single-attached units, and laid-back bungalow units, we have it
              all.
            </p>
          </div>
          <div className="color-overlay"></div>
        </div>

        <div className="properties">
          <h2>Choose Your Property</h2>
          {/* Mobile View */}
          <div className="flip-card-container">
            <FlipCardComponent
              title="Calista End"
              cardID="calistaEnd"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/calistaEnd.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-end.jpg"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 60.5sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={1}
              color="#379144"
            />
            <FlipCardComponent
              title="Calista Mid"
              cardID="calistaMid"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/calistaMid.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-mid.jpg"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 44sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={2}
              color="#379144"
            />
            <FlipCardComponent
              title="Calista Pair"
              cardID="calistaPair"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/newpair.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-pair.jpg"
              specs={[
                "Floor Area: 80sqm",
                "Typical Lot Area: 104.5sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={3}
              color="#379144"
            />
            <FlipCardComponent
              title="Unna"
              cardID="unna"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Pandi%20Exterior.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-unna.jpg"
              specs={[
                "Floor Area: 54sqm",
                "Typical Lot Area: 88sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={4}
              color="#379144"
            />
            <FlipCardComponent
              title="Brenna"
              cardID="brenna"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/PPHI%20BATULAO.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-brenna.jpg"
              specs={[
                "Floor Area: 45sqm",
                "Typical Lot Area: 88sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={5}
              color="#379144"
            />
          </div>
          {/* Web View */}
          <div className="pphiScene">
            <PphiCardComponent
              title="Calista End"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/calistaEnd.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-end.jpg"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 60.5sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
            />
            <PphiCardComponent
              title="Calista Mid"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/calistaMid.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-mid.jpg"
              specs={[
                "Floor Area: 40sqm",
                "Typical Lot Area: 44sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
            />
            <PphiCardComponent
              title="Calista Pair"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/newpair.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-pair.jpg"
              specs={[
                "Floor Area: 80sqm",
                "Typical Lot Area: 104.5sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
            />
            <PphiCardComponent
              title="Unna"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Pandi%20Exterior.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-unna.jpg"
              specs={[
                "Floor Area: 54sqm",
                "Typical Lot Area: 88sqm",
                "2 Bathrooms",
                "3 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
              cardKey={1}
            />
            <PphiCardComponent
              title="Brenna"
              imgFront="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/PPHI%20BATULAO.png"
              imgBack="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/Cartland-interior-brenna.jpg"
              specs={[
                "Floor Area: 45sqm",
                "Typical Lot Area: 88sqm",
                "1 Bathroom",
                "2 Bedrooms",
                "1 Carport",
                "Expandable",
              ]}
              link="https://www.phirsteditions.com.ph/cartland"
            />
          </div>
        </div>

        <div className="pphiPillar">
          <div className="pphiPillar-content">
            <p>
              Fall in love with PHirst’s beautifully{" "}
              <b className="pphiWrapper-pillar">
                <motion.span
                  className="pphiAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut" }}
                  viewport={{ once: true }}
                >
                  Complete
                </motion.span>

                <b className="pphiMain-text">Complete</b>
              </b>{" "}
              homes.
            </p>
            <p>
              Where engaging &{" "}
              <b className="pphiWrapper-pillar">
                <motion.span
                  className="pphiAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
                  viewport={{ once: true }}
                >
                  Conceptive
                </motion.span>

                <b className="pphiMain-text">Conceptive</b>
              </b>{" "}
              amenities are brought to life.
            </p>
            <p>
              Where everyone is{" "}
              <b className="pphiWrapper-pillar">
                <motion.span
                  className="pphiAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 2 }}
                  viewport={{ once: true }}
                >
                  Connected
                </motion.span>

                <b className="pphiMain-text">Connected</b>
              </b>{" "}
              to life’s essentials.
            </p>
            <p>
              Where owning your first home is a{" "}
              <b className="pphiWrapper-pillar">
                <motion.span
                  className="pphiAnimation-pillar"
                  initial={{ width: "0ch" }}
                  whileInView={{ width: "auto" }}
                  transition={{ duration: 2, ease: "easeInOut", delay: 3 }}
                  viewport={{ once: true }}
                >
                  Convenient
                </motion.span>

                <b className="pphiMain-text">Convenient</b>
              </b>{" "}
              and guided journey.
            </p>
          </div>
        </div>

        <div className="video-embed">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SO1aTppp-sE??controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <a
            className="pphiBtn"
            href="https://www.phirstparkhomes.com"
            target="_blank"
          >
            <span class="pphi__icon-wrapper">
              <svg
                width="10"
                class="pphi__icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>

              <svg
                class="pphi__icon-svg  pphi__icon-svg--copy"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>
            </span>
            Visit the Website to learn more
          </a>
        </div>
        <ContactFormTemplate email="ask@phirstparkhomes.com" />
        <Footer />
      </div>
    </>
  );
};

const PphiCardComponent = (props) => {
  return (
    <div className="card">
      <div className="card__face card__face--front">
        <div className="card-title">{props.title}</div>
        <div className="img-gradient"></div>
        <img src={props.imgFront} />
      </div>
      <div className="card__face card__face--back">
        <div className="pphiSpecification">
          <b className="">Specifications</b>
          {props.specs.map((item, key) => {
            return <div key={key}>{item}</div>;
          })}
          <a href={props.link} target="_blank">
            See More
          </a>
        </div>
        <img src={props.imgBack} />
      </div>
    </div>
  );
};

export default PphiPage;
