import React from "react";

import { useNavigate } from 'react-router-dom'


const BackBtn = () => {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <>
            <img className="back-btn-v1" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/back-btn-v1.png" alt="" onClick={ goBack } />
        </>
    )
}

export default BackBtn