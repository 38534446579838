import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import '../css/post.css'

import axios from "axios";


const Post = () => {
    const location = useLocation()

    const [ pages, setPages ] = useState([])

    const [ job, setJob ] = useState({})
    const [ isDataRetrieved, setDataState ] = useState(false)

    const [ jobData, setJobData ] = useState({job_title: '', job_type: '', employee_type: '', job_location: '', job_specs: ''})
    const [ cResponsibilities, setResponsibilities ] = useState(null)
    const [ cRequirements, setRequirements ] = useState(null)

    const [ isRespNotEmpty, setRespState ] = useState(false) // Responsibilities
    const [ isReqNotEmpty, setReqState ] = useState(false) // Requirements

    useEffect(() => {
        getCareer(location.state.jobId)
    }, [])

    useEffect(() => {
        if (jobData.job_title != '') {
            // getCareerResponsibilities(jobData.job_id)
            // getCareerRequirements(jobData.job_id)
            console.log(jobData)
        }
    }, [jobData])

    useEffect(() => {
        if (cResponsibilities != null) {
            console.log(cResponsibilities)
            setRespState(true)
        }
    }, [cResponsibilities])

    useEffect(() => {
        if (cRequirements != null) {
            console.log(cRequirements)
            setReqState(true)
        }
    }, [cRequirements])

    // Get Career
    const getCareer = (id) => {
        const hostname = window.location.protocol + '//' + window.location.hostname
        const url = hostname + ':3001/api/career/'
        axios.get(url + id)
          .then(response => {
            // Handle the response data
            response.data.map((item) => {
                setJobData(item)
            })
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
    }

    // Get responsibilities of career
    const getCareerResponsibilities = async (id) => {
        try {
            const hostname = window.location.protocol + '//' + window.location.hostname
            const url = hostname + ':3001/api/career/resp/'
            await axios.get(url + id)
              .then(response => {
                // Handle the response data
                response.data.map((item) => {
                    setResponsibilities(response.data)
                })
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
        } catch (err) {
            console.log(err)
        }
    }

    // Get requirements of career
    const getCareerRequirements = async (id) => {
        try {
            const hostname = window.location.protocol + '//' + window.location.hostname
            const url = hostname + ':3001/api/career/requirements/'
            await axios.get(url + id)
              .then(response => {
                // Handle the response data
                if (response.data.length != 0) {
                    setRequirements(response.data)
                } else {
                    setRequirements([])
                }
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            <div className="content job">
                <div className="job-cont">
                    <div className="job-content section-1">
                        <h2>{ jobData.job_title }</h2>
                        <div className="job-item">
                            <p>Job Type: </p>
                            <p>{ jobData.job_type }</p>
                        </div>
                        <div className="job-item">
                            <p>Employee Type: </p>
                            <p>{ jobData.employee_type }</p>
                        </div>
                        <div className="job-item">
                            <p>Location: </p>
                            <p>{ jobData.job_location }</p>
                        </div>
                        {/* <div className="job-item">
                            <p>Work Schedule: </p>
                            <p>{ jobData.job_sched }</p>
                        </div> */}
                    </div>
                    <div className="job-content section-2">
                        <div className="job-desc">
                            <h4>Summary</h4>
                            <p>{ jobData.job_specs }</p>
                        </div>
                        {/* <h4>Responsibilities:</h4>
                        <ul>
                            { isRespNotEmpty ?
                                cResponsibilities.map((item, index) => {
                                    return <li key={index}>{item.resp_desc}</li>
                                })
                                : <></>
                            }
                        </ul> */}
                        {/* <h4>Qualifications:</h4>
                        <ul>   
                        </ul> */}
                    </div>
                    {/* <div className="job-content section-3">
                        <h4>Requirements:</h4>
                        <ul>
                            { isReqNotEmpty ?
                                cRequirements.map((item, index) => {
                                    return <li key={index}>{item.req_desc}</li>
                                })
                                : <></>
                            }
                        </ul>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Post