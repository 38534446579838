import React, { useEffect, useState } from "react";

import '../css/job-form.css'
import axios from "axios";

import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const JobForm = (props) => {
    const careerLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/phirst-careers-logo.png'
    const applicationSuccess = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/application-success.png'
    const applicationError = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/application-error.png'

    const [ isModalShown, setModalState ] = useState(false)
    const [ careerCollection, setCareerCollection ] = useState([])
    const [ applicationCount, setCount ] = useState(0)

    const source = axios.CancelToken.source()

    const [ fieldName, setFieldName ] = useState('')
    const [ fieldCareer, setFieldCareer ] = useState('')
    const [ fieldResume, setFieldResume ] = useState(null)
    const [ fieldCoverLetter, setFieldCoverLetter ] = useState(0)

    const { REACT_APP_MAIL_API_URL, REACT_APP_MAIL_API_KEY, REACT_APP_MAIL_RECEIVER, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_SECRET_KEY} = process.env
    
    const [ fieldData, setFieldData ] = useState({
        name: '',
        career: '',
        resume: null,
        coverLetter: null
    })

    useEffect(() => {
        if (props.length != 0) {
            setModalState(props.modalState)
        }
    })
    
    const changeCareerSelected = () => {
        let selectedOption = document.getElementById('careerList')
        let careerValue
        if (selectedOption) {
            for (let i=0; i < selectedOption.options.length; i++) {
                let option = selectedOption[i]
                if (option.id == props.jobId) {
                    option.selected = true
                    careerValue = option.value
                }
            }
        }
        setFieldCareer(careerValue)
    }

    useEffect(() => {
        getCareers(source.token)
        return () => {
            source.cancel('Requested Cancel by cleanup')
        }
    }, [])

    useEffect(() => {
        changeCareerSelected()
        let formModal = document.getElementById('formModal')
        let bgOverlay = document.getElementById('bgOverlay')
        if (isModalShown) {
            bgOverlay.style.display = 'block'
            formModal.style.display = 'flex'
            setTimeout(() => {
                formModal.classList.add('show')
                bgOverlay.classList.add('show')
            }, 100)
        } else {
            formModal.classList.remove('show')
            bgOverlay.classList.remove('show')
            setTimeout(() => {
                bgOverlay.style.display = 'none'
                formModal.style.display = 'none'
            }, 240)
        }
    }, [isModalShown])

    useEffect(() => {
    }, [careerCollection])

    useEffect(() => {
        setFieldData((prevState) => ({
            ...prevState,
            name: fieldName
        }))
    }, [fieldName])

    useEffect(() => {
        setFieldData((prevState) => ({
            ...prevState,
            career: fieldCareer
        }))
    }, [fieldCareer])

    useEffect(() => {
        setFieldData((prevState) => ({
            ...prevState,
            resume: fieldResume
        }))
    }, [fieldResume])

    useEffect(() => {
        setFieldData((prevState) => ({
            ...prevState,
            coverLetter: fieldCoverLetter
        }))
    }, [fieldCoverLetter])


    useEffect(() => {
        if (applicationCount != 0) {
            console.log(applicationCount)
        }
    }, [applicationCount])

    const getCareers = async (cancelToken) => {
        try {
            // const hostname = window.location.protocol + '//' + window.location.hostname
            // const url = hostname + ':3001/api/retrieve/careers/title'
            const envDomain = process.env.REACT_APP_DOMAIN_NAME
            const envPort = process.env.REACT_APP_BACKEND_PORT
            // const endpoint = envDomain + ':' + envPort + '/api/retrieve/careers/title'
            const endpoint = envDomain + '/api/retrieve/careers/title'
            await axios.get(endpoint, {cancelToken})
            .then(response => {
                // Handle the response data
                setCareerCollection(response.data)
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
        } catch (err) {
            console.log(err)
        }
    }

    // const getApplicationCount = async (cancelToken) => {
    //     try {
    //         const hostname = window.location.protocol + '//' + window.location.hostname
    //         const url = hostname + ':3001/api/retrieve/careers/application/count'
    //         await axios.get(url, {cancelToken})
    //         .then(response => {
    //             // Handle the response data
    //             setCount(response.data[0].item_count)
    //         })
    //         .catch(error => {
    //             // Handle any errors
    //             console.error(error);
    //         });
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const inputHandler = (e) => {
        switch (e.target.name) {
            case "name":
                setFieldName(e.target.value)    
            break;
            case "career":
                setFieldCareer(e.target.value)    
            break;
            case "resume":
                if (e.target.files[0]) {
                    const resumeFileName = document.getElementById('ResumeFileName')
                    setFieldResume(e.target.files[0])
                    resumeFileName.innerHTML = e.target.files[0].name
                }
                // console.log("test: " + e.target.files[0])
            break;
            case "cover_letter": 
                if (e.target.files[0]) {
                    const coverLetterFileName = document.getElementById('clFileName')
                    setFieldCoverLetter(e.target.files[0])    
                    coverLetterFileName.innerHTML = e.target.files[0].name
                }
            break;
        
            default:
                break;
        }
    }

    const s3Client = new S3Client({
        endpoint: 'https://sgp1.digitaloceanspaces.com',
        forcePathStyle: false,
        region: 'sgp1',
        credentials: {
            accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SPACES_SECRET_KEY
        }
    })

    const getDateTime = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        const datetime = `${month}-${day}-${year}`

        return datetime

    }

    const uploadObject = async (file, desc) => {
        let fileDataType
        let fileExt
        
        if (desc === 'resume') {
            fileDataType = fieldData.resume.type
            fileExt = fieldData.resume.name.split('.').pop()
        } else if (desc === 'cover-letter') {
            fileDataType = fieldData.coverLetter.type
            fileExt = fieldData.coverLetter.name.split('.').pop()
        }
        const formData = new FormData()
        formData.append('file', file)


        const dateTime = getDateTime()
        const params = {
            Bucket: 'phirstpark-editions',
            Key: 'phirst-corporate/career-files/'+ fieldData.career + '/' + fieldData.name + '/' + fieldData.name + '-' + desc + '-' + dateTime + '.' + fileExt,
            Body: file,
            ACL: "public-read",
        }
        try {
            const data = await s3Client.send(new PutObjectCommand(params))
            const url = 'https://' + params.Bucket + ".sgp1.cdn.digitaloceanspaces.com/" + params.Key
            // console.log("Successfully uploaded object: " + url)
            return url;
        } catch (err) {
            console.log("Error uploading file:", err)
        }

    }
    
    const mailContent = (resumeUrl, clUrl) => {

        return (
                '<!DOCTYPE html lang="en"> \
                    <head> \
                        <meta http-equiv="X-UA-Compatible" content="IE=edge"> \
                        <meta name="viewport" content="width=device-width, initial-scale=1"> \
                        <meta name="color-scheme" content="light dark"> \
                        <meta name="supported-color-schemes" content="light dark"> \
                        <title>Our new office awaits! | Bayer Office Inauguration</title> \
                        <style type="text/css"> \
                            * { \
                                color: black; \
                            } \
                            body { \
                                padding: 0; \
                                margin: 0; \
                                background-color: #fff; \
                            } \
                            div[class="content"] { \
                                display: flex; \
                                flex-direction: column; \
                                width: 100%; \
                                max-width: 720px; \
                                margin: 0 auto; \
                            } \
                            div[class="content"] div[class="text-content"]{ \
                                box-sizing: border-box; \
                                padding: 24px 40px;    \
                            } \
                            img[class="brand-img"] { \
                                width: 100%; \
                            } \
                            \
                            div[class="content"] p { \
                                margin-bottom: 48px; \
                            } \
                            div[class="content"] h6 { \
                                margin: unset; \
                                font-size: 16px; \
                                font-weight: normal; \
                            } \
                            \
                        </style>  \
                    </head> \
                    <body> \
                        <div class="content" style="width: 100%; max-width: 720px; margin: 0 auto;"> \
                            <a href="#" style="width: 100%;"><img class="brand-img" style="width: 100%;" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/PHirst%20Careers%20banner.jpg" alt=""></a>\
                            <div class="text-content" style="box-sizing: border-box; padding: 24px 40px;"> \
                                <h4 style="font-size: 18px;font-weight: normal;text-transform: uppercase;font-weight: bold;"><span style="">Career Application</span></h4> \
                                <p style="margin-bottom: 8px;font-size: 16px;">Applying for: ' + fieldData.career + '</p> \
                                \
                                <h6 style="margin: unset; font-size: 15px; font-weight: normal;">From: ' + fieldData.name + '</h6> \
                                <p style="font-size: 14px;">Download links:</p>\
                                <a href="' + resumeUrl + '">Click here to download resume.</a><br>\
                                <a href="' + clUrl + '">Click here to download cover letter.</a>\
                            </div> \
                        </div> \
                    </body> \
                </html>'
            )
    }

    const emailSending = async (resumeUrl, clUrl) => {

        const mailData = {
            sender: {email: 'phirstcorp@gmail.com'},
            to: [{email: process.env.REACT_APP_MAIL_RECEIVER_CAREERS}],
            htmlContent: mailContent(resumeUrl, clUrl),
            // textContent: "Test Content",
            subject: "Career Application: " + fieldData.career,
            params: {
                fname: fieldData.name,
                career: fieldData.career
            }
        }

        const data = JSON.stringify(mailData)
        
        const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'api-key': REACT_APP_MAIL_API_KEY
            },
            body: data,
        };

        // Send email using API endpoint
        await fetch(REACT_APP_MAIL_API_URL+'smtp/email', options)
            .then((response) => {
                if(response.ok) {
                    console.log("Your email has been sent!")
                } 
            })
            .catch(err => console.error("Something went wrong. Please try again.",err));
    }

    const formSubmission = async (e, cancelToken) => {
        const submitBtn = document.getElementById('submitApp')
        submitBtn.textContent = 'Submitting...'
        e.preventDefault()
        if (fieldData.career === '') {
            const careerSelect = document.getElementById('careerList')
            const value = careerSelect.value
            setFieldData((prevState) => ({
                ...prevState,
                career: value
            }))
        }   

        const resumeUrl = await uploadObject(fieldData.resume, 'resume')
        const coverLetterUrl = await uploadObject(fieldData.coverLetter, 'cover-letter')

        // const hostname = window.location.protocol + '//' + window.location.hostname
        // const url = hostname + ':3001/api/post/application/submit'
        const envDomain = process.env.REACT_APP_DOMAIN_NAME
        const envPort = process.env.REACT_APP_BACKEND_PORT
        // const endpoint = envDomain + ':' + envPort + '/api/post/application/submit'
        const endpoint = envDomain + '/api/post/application/submit'
        const values = {
            name: fieldData.name,
            career: fieldData.career,
            resume: resumeUrl,
            coverLetter: coverLetterUrl
        }
        await axios.post(endpoint, values, {cancelToken: cancelToken})
            .then(async (response) => {
                if (response.status === 200) {
                    await emailSending(resumeUrl, coverLetterUrl)
                    props.closeModal()
                    setTimeout(() => {
                        // window.alert("Your application has been submitted!")
                        showAppModal(applicationSuccess,'Your Application was submitted successfully!', '', 'success')
                    }, 200)
                }
            })
            .catch((err) => {
                showAppModal(applicationError,'Oops! Something went wrong.', 'Your application submission cannot be completed at this time. Please try again later.')
            })

        submitBtn.textContent = 'Submit Application'

    }
    
    const showAppModal = (imgUrl, appState, appDesc, state) => {
        const appModal = document.getElementById('appResult')
        const appImg = document.getElementById('appImg')
        const appStateElement = document.getElementById('applicationState')
        const appDescElement = document.getElementById('applicationDesc')
        
        appImg.setAttribute('src', imgUrl)
        appStateElement.textContent = appState
        appDescElement.textContent = appDesc
        if (state === 'error') {
            appModal.classList.add('error')
        } else {
            appModal.classList.remove('error')
        }

        appModal.style.display = 'flex'
        setTimeout(() => {
            appModal.classList.remove('hide')
        },100)
    }

    const closeAppModal = () => {
        const appModal = document.getElementById('appResult')
        appModal.classList.add('hide')
        setTimeout(() => {
            appModal.style.display = 'none'
        }, 420)
    }

    return (
        <>
            <div className="form-modal hide" id="formModal">
                <div className="modal-header">
                    <img className="modal-logo" src={careerLogo} alt="" />
                </div>
                <button className="close-btn" onClick={props.handleClick}><i className="bi bi-x-circle"></i></button>
                <div className="modal-body">
                    <form action="" onSubmit={(e) => {formSubmission(e, source.token)}}>
                        <div className="input-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" onChange={ (e) => { inputHandler(e) } } required/>
                        </div>
                        <div className="input-group">
                            <label htmlFor="careerList">Choose Career:</label>
                            <select name="career" id="careerList" onChange={ (e) => { inputHandler(e) } }>
                                {
                                    careerCollection.map((item, index) => {
                                        return <option key={index} value={item.job_title} id={item.job_id}>{item.job_title}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-group file">
                            <p>Resume: </p>
                            <div>
                                <label htmlFor="resume"><i className="bi bi-upload"></i> Choose File</label>
                                <input id="resume" name="resume" type="file" accept=".pdf, .doc, .docx, .rtf, .png, .jpeg, .jpg, .bmp, .ppt, .pptx, .csv, .gif, .jng" onChange={ (e) => { inputHandler(e) } } encType="multipart/form-data" required/>
                                <span id="ResumeFileName">No file chosen...</span>
                            </div>
                        </div>
                        <div className="input-group file">
                            <p>Cover Letter: </p>
                            <div>
                                <label htmlFor="coverLetter"><i className="bi bi-upload"></i> Choose File</label>
                                <input id="coverLetter" name="cover_letter" type="file" accept=".pdf, .doc, .docx, .rtf, .png, .jpeg, .jpg, .bmp, .ppt, .pptx, .csv, .gif, .jng" onChange={ (e) => { inputHandler(e) } } encType="multipart/form-data" required/>
                                <span id="clFileName">No file chosen...</span>
                            </div>
                        </div>
                        <hr />
                        <div className="btn-cont">
                            {/* <button className="cancel-btn" type="button" onClick={props.handleClick}>Cancel</button> */}
                            <button className="submit-btn" type="submit" id="submitApp">Submit Application</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="form-bg-overlay hide" id="bgOverlay"></div>
            <div className="application-result hide" id="appResult">
                <img src={ applicationSuccess } alt="" id="appImg"/>
                <div className="text-cont">
                    <p className="app-state" id="applicationState"></p>
                    <p className="app-desc" id="applicationDesc"></p>
                </div>
                <button onClick={ closeAppModal }>Close</button>
            </div>
        </>
    )
}

export default JobForm