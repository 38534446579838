import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ComingSoon from "./pages/coming-soon/components/index-coming-soon";

import Landing from "./pages/landing-page/components/main";
import Navbar from "./pages/partials/components/navbar";
import AboutUs from "./pages/about-us/components/index-about";
import ContactUs from "./pages/contact-us/components/contact-index";
import PrivacyPolicyV2 from "./pages/privacy-policy/components/privacyPolicy";

import EditionsPage from "./pages/editions/components/editions-index";
import SightsPage from "./pages/sights/components/sights-index";
import CentralePage from "./pages/centrale/components/centrale-index";
import PphiPage from "./pages/pphi/components/pphi-index";
import ImpressionsPage from "./pages/impressions/components/impressions-index";
import PhirstCareers from "./pages/phirst-careers/components/index-careers";
import Explore from "./pages/explore/components/explore-index";
import CareerList from "./pages/phirst-careers/components/career-list";
import Post from "./pages/phirst-careers/components/post";

import JobListing from "./pages/phirst-careers/components/jobs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navbar />
    <Routes>
      {/* <Route path='/' element={ <ComingSoon/> }/> */}
      <Route path="/" element={<Landing />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="explore" element={<Explore />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="careers" element={<></>} />
      <Route path="privacy-policy" element={<PrivacyPolicyV2 />} />
      <Route path="phirst-editions" element={<EditionsPage />} />
      <Route path="phirst-sights" element={<SightsPage />} />
      <Route path="phirst-centrale" element={<CentralePage />} />
      <Route path="phirst-park-homes" element={<PphiPage />} />
      <Route path="phirst-impressions" element={<ImpressionsPage />} />
      <Route path="phirst-careers">
        <Route index element={<PhirstCareers />} />
        {/* <Route path='careers' element={ <CareerList/> } /> */}
        <Route path="job" element={<Post />} />
        <Route path="jobs" element={<JobListing />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
