import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";

import "../css/navbar.css";
import "../css/navbar-custom.css";

const Navbar = () => {
  const [isLandingPage, setLandingPageState] = useState("");
  const [isMenuToggled, setMenuState] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname != null) {
      if (location.pathname === "/") {
        setLandingPageState(true);
      } else {
        setLandingPageState(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isLandingPage != null) {
      if (isLandingPage) {
        // console.log("Landing page")
      } else {
        // console.log("Other Page")
      }
    }
  }, [isLandingPage]);

  const toggleMenu = () => {
    setMenuState((prevState) => !prevState);
  };

  return (
    <>
      {/* { isLandingPage ? <NavbarCustom/> : <NavbarAll/> } */}
      {/* <NavbarAll isMenuToggled={isMenuToggled} toggleMenu={toggleMenu}/> */}

      <NavbarCustom isMenuToggled={isMenuToggled} toggleMenu={toggleMenu} />
    </>
  );
};

const NavbarAll = (props) => {
  return (
    <>
      <nav className="nav-all" id="navbar">
        <div className="logo-cont">
          <NavLink to={"/"} className={"logo-anchor"}>
            <img
              className="logo"
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/phirst-logo-white.png"
              alt=""
            />
          </NavLink>

          <svg height="90" width="320" className="logo-bar desktop">
            <polygon
              points="0,0 320,0 240,90 0,90"
              style={{ fill: "#379144" }}
            />
          </svg>
          <svg height="72" width="240" className="logo-bar mobile">
            <polygon
              points="0,0 240,0 180,72 0,72"
              style={{ fill: "#379144" }}
            />
          </svg>
        </div>

        <ul className="nav-links desktop">
          <li>
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li className="nav-item dropdown">
            <a href="#" className="nav-link">
              Residences <i className="bi bi-caret-down-fill"></i>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/phirst-park-homes">PHirst Park Homes</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-editions">PHirst Editions</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-impressions">PHirst Impressions</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-sights">PHirst Sights</NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a href="#" className="nav-link">
              Master-Planned Communities{" "}
              <i className="bi bi-caret-down-fill"></i>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/phirst-centrale">PHirst Centrale</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/phirst-careers">Careers</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </li>
        </ul>

        <img
          className="mobile-menu-btn"
          src={
            props.isMenuToggled
              ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/menu-green-v2.png"
              : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/menu-green-alt-v2.png"
          }
          alt=""
          onClick={props.toggleMenu}
        />
        <div
          className={
            "mobile-menu-dropdown " + (props.isMenuToggled ? "show" : "")
          }
        >
          <ul>
            <li>
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-park-homes">PHirst Park Homes</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-editions">PHirst Editions</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-impressions">PHirst Impressions</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-sights">PHirst Sights</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-centrale">PHirst Centrale</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-careers">Careers</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const NavbarCustom = (props) => {
  return (
    <>
      <nav className="nav-custom" id="navbar">
        <div className="logo-cont">
          <NavLink to={"/"} className={"logo-anchor"}>
            <img
              className="logo"
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/PHirst%20Corporate%20Site%20Logo.png"
              alt=""
            />
          </NavLink>
        </div>

        <ul className="nav-links desktop">
          <li>
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li className="nav-item dropdown">
            <a href="#" className="nav-link">
              Residences <i className="bi bi-caret-down-fill"></i>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/phirst-park-homes">PHirst Park Homes</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-editions">PHirst Editions</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-impressions">PHirst Impressions</NavLink>
              </li>
              <li>
                <NavLink to="/phirst-sights">PHirst Sights</NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a href="#" className="nav-link">
              Master-Planned Communities{" "}
              <i className="bi bi-caret-down-fill"></i>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/phirst-centrale">PHirst Centrale</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/phirst-careers">Careers</NavLink>
          </li>
          <li>
            <NavLink to="/explore">Explore</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </li>
          <li className="social">
            <a
              href="https://www.facebook.com/PHirstOfficialFacebookPage"
              target="_blank"
            >
              <img
                classname=""
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-facebook-v2.png"
                alt=""
              />
            </a>
            <a href="https://www.instagram.com/phirst.ph/" target="_blank">
              <img
                classname=""
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-instagram-v2.png"
                alt=""
              />
            </a>
            <a href="https://www.tiktok.com/@phirst_ph" target="_blank">
              <img
                classname=""
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-tiktok-v2.png"
                alt=""
              />
            </a>
            <a href="https://www.youtube.com/@PHirst" target="_blank">
              <img
                classname=""
                src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/social-youtube.png"
                alt=""
              />
            </a>
          </li>
        </ul>

        <img
          className="mobile-menu-btn"
          src={
            props.isMenuToggled
              ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/menu-green-v2.png"
              : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/others/menu-green-alt-v2.png"
          }
          alt=""
          onClick={props.toggleMenu}
        />
        <div
          className={
            "mobile-menu-dropdown " + (props.isMenuToggled ? "show" : "")
          }
        >
          <ul>
            <li>
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-park-homes">PHirst Park Homes</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-editions">PHirst Editions</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-impressions">PHirst Impressions</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-sights">PHirst Sights</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-centrale">PHirst Centrale</NavLink>
            </li>
            <li>
              <NavLink to="/phirst-careers">Careers</NavLink>
            </li>
            <li>
              <NavLink to="/explore">Explore</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
