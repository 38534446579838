import React, { useEffect, useState } from "react";

import "../css/centrale.css";
import "../css/cardComponent.css";
import Footer from "../../partials/components/Footer";
import LoadScreen from "../../others/components/loadScreen";

import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackBtn from "../../others/components/backBtn";

const CentralePage = () => {
  const bannerImg =
    "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/centrale-banner-bg-2.jpg";
  const [loadState, setLoadState] = useState(false);

  useEffect(() => {
    setLoadState(true);
  }, []);

  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="content centrale">
        <div
          className="banner"
          style={{ backgroundImage: 'url("' + bannerImg + '"' }}
        >
          <div className="text-content">
            {/* <h1>PHirst <span>Centrale</span></h1> */}
            {/* <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/centrale-logo.png" className="header-img-centrale"></img> */}
            <img
              src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/centrale-logo-v2-500.png"
              className="header-img-centrale"
            ></img>
            <p>
              Welcome to PHirst Centrale, a vibrant and dynamic township that
              offers a truly unique living experience. Anchored on three
              branding pillars, our township provides a perfect balance of
              comfortable living, commercial and institutional amenities, and
              exciting retail destinations.
            </p>
          </div>
          <div className="color-overlay"></div>
        </div>
        <div className="properties">
          <h2>Choose Your Ideal Home, Workspace, and Lifestyle</h2>
          <div className="card-container">
            <CentraleCardComponent
              title={
                <>
                  <h4>DWELL</h4>{" "}
                  <span>Your Home at the heart of PHirst Centrale</span>
                </>
              }
              cardID="dwell"
              cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-dwell.jpg"
              cardBg2="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-dwell.jpg"
              link1="https://phirsteditions.com.ph/"
              link2="https://phirstcentrale.com.ph/phirst-impressions"
              cardKey={1}
            />
            <CentraleCardComponent
              title={
                <>
                  <h4>Create</h4> <span>The commerce hub</span>
                </>
              }
              subDesc="The Fairgrounds"
              cardID="create"
              cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-create.jpg"
              cardBg2="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-create.jpg"
              link1="https://phirstcentrale.com.ph/phirst-fairgrounds"
              link2=""
              cardKey={2}
            />
            <CentraleCardComponent
              title={
                <>
                  <h4>Thrive</h4> <span>Your retail destination</span>
                </>
              }
              subDesc="The Boroughs"
              cardID="thrive"
              cardBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-thrive.jpg"
              cardBg2="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/card-bg-thrive.jpg"
              link1="https://phirstcentrale.com.ph/phirst-boroughs"
              link2=""
              cardKey={2}
            />
          </div>
        </div>
        {/* <div className="about-centrale">
                    <div className="text-content">
                        <h5>About PHirst Centrale</h5>
                        <h2>BE AT THE CENTER OF EVERYTHING!</h2>
                        <p>DWELL features two remarkable residential options: PHirst Impressions (townhouse) and PHirst Editions (premium single attached houses). Both are designed to meet your lifestyle needs and offer comfort, convenience, and security.</p>
                        <p>CREATE boasts The Fairgrounds, a commercial and institutional area that is perfect for entrepreneurs, startups, and established businesses alike. It's a space where you can unleash your creativity and grow your business.</p>
                        <p>THRIVE showcases The Boroughs, a vibrant retail destination where you can shop, dine, and enjoy leisure activities. Here, you can experience the perfect blend of modern living and traditional values.</p>
                        <p>At PHirst Centrale, we are committed to providing a sustainable and welcoming community where you can thrive. Come and be a part of our dynamic township and experience the best that life has to offer!</p>
                    </div>
                </div> */}

        <div className="video-embed">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1bPE4BeKQ5w?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <a
            className="centraleBtn"
            href="https://phirstcentrale.com.ph"
            target="_blank"
          >
            <span class="centrale__icon-wrapper">
              <svg
                width="10"
                class="centrale__icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>

              <svg
                class="centrale__icon-svg centrale__icon-svg--copy"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                fill="none"
                viewBox="0 0 14 15"
              >
                <path
                  fill="currentColor"
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                ></path>
              </svg>
            </span>
            Visit the Website to learn more
          </a>
        </div>
        <ContactFormTemplate email="phirstcentrale@gmail.com" />
        <Footer />
      </div>
    </>
  );
};

const CentraleCardComponent = (props) => {
  const [isCardFlipped, setCardState] = useState();
  const [isFirstCard, setFirstCard] = useState(false);

  useEffect(() => {
    if (props.cardKey == 1) {
      setFirstCard(true);
    }
  }, []);

  useEffect(() => {
    if (isCardFlipped != null) {
      if (isCardFlipped) {
        let card = document.getElementById(props.cardID);
        card.classList.add("show");
        console.log("test");
      } else {
        let card = document.getElementById(props.cardID);
        card.classList.remove("show");
      }
    }
  }, [isCardFlipped]);

  return (
    <>
      <div
        className="card"
        id={props.cardID}
        key={props.cardKey}
        onClick={() => {
          setCardState((prevState) => !prevState);
        }}
      >
        <div className="card-inner">
          <div className="card-front">
            <div className="text-content">{props.title}</div>
            <div className="color-overlay"></div>
            <div
              className="bg-overlay"
              style={{ backgroundImage: 'url("' + props.cardBg + '")' }}
            ></div>
          </div>
          <div className="card-back">
            <div className="text-content">
              {isFirstCard ? (
                <>
                  <div className="half-editions">
                    <h5>PHirst Editions</h5>
                    <a href={props.link1} target="_blank">
                      See More
                    </a>
                  </div>
                  <div className="half-impressions">
                    <h5>PHirst Impressions</h5>
                    <a href={props.link2} target="_blank">
                      See More
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <h5>{props.subDesc}</h5>
                  <a href={props.link1} target="_blank">
                    See More
                  </a>
                </>
              )}
            </div>
            <div className="color-overlay"></div>
            <div
              className="bg-overlay"
              style={{ backgroundImage: 'url("' + props.cardBg2 + '")' }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CentralePage;
