import React, { useEffect, useState } from "react";

import '../assets/css/main.css'
import { coords } from "./coordinates";

import ImageMapper from 'react-img-mapper'

import Controller from "./musicController";
import Modal from "./modal";
import Blob from "./blob";

import LoadScreen from "../../others/components/loadScreen";

// import LoadScreen from "../../others/loadScreen";



// Custom Hook to get Browser Window size
const Size = () => {

    const [ windowWidth, setWindowWidth ] = useState(0);
    
    const getWidth = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', getWidth);
        
        return () => {
            window.removeEventListener('resize', getWidth());
        }

    }, []);
    

    return windowWidth;
}

const windowSize = () => {
    const size = window.innerWidth
    // if ( size <= 1920) {
    //     return window.innerWidth
    // } else {
    //     return 1920
    // }
    return size
    
}

// get center of mapping
const getAreaCenter = (coords) => {
    var coordsArray = coords.split(','), center = []

    var coord, 
        maxX = parseInt(coordsArray[0], 10),
        minX = maxX,
        maxY = parseInt(coordsArray[1], 10),
        minY = maxY;

    for (var i = 0, l = coordsArray.length; i < l; i++) {
        coord = parseInt(coordsArray[i], 10)

        if(i%2 === 0) {
            if (coord < minX) {
                minX = coord
            } else if ( coord > maxX) {
                maxX = coord
            }
        } else {
            if (coord < minY) {
                minY = coord
            } else if (coord > maxY) {
                maxY = coord
            }
        }
    }
    center = [parseInt((minX + maxX) / 2, 10), parseInt((minY + maxY) / 2, 10)];

    return center
}


const Landing = () => {

    const [ isModalVisible, setisModalVisible ] = useState(false)
    const [ pos, setPos] = useState({x: 0,y: 0})

    const [ modalContent, setModalContent] = useState({})

    const [ blobPos1, setBlobPos1 ] = useState({ x: 0, y: 0})
    const [ blobPos2, setBlobPos2 ] = useState({ x: 0, y: 0})
    const [ blobPos3, setBlobPos3 ] = useState( { x: 0, y: 0})
    const [ blobPos4, setBlobPos4 ] = useState( { x: 0, y: 0})
    const [ blobPos5, setBlobPos5 ] = useState( { x: 0, y: 0})
    const [ windowWidth, setWindowWidth ] = useState(0)


    const [time, setTime] = useState({ hour: "", minute: "", second: "" })

    const [ isDayOrNight, setDayOrNight] = useState({ day: false, night: false })


    const videoLinks = { 

        // day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/3rd%20Revision%2004-23-24.mp4",
        // day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/corp-day-202405020853.mp4",
        day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/20240530-corp-daytime.mp4",
        //night: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/3rd%20Revision%20Night%20Time%2004-23-24.mp4"
        night: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/4th%20Revision%20Night%20time%2005-29-24.mp4"
        
    }
    const imgPlaceholderLinks = {
        //day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/placeholder-day-202404250742.jpg",
        day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/placeholder-day-202405020905.jpg",
        night: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/placeholder-night-202404251139.jpg"
    }


    const [isLoading, setIsLoading] = useState(true)
    const [ isIntro, setIntro ] = useState()

    // method to get current time
    const getDate = () => {
        var d = new Date();
        setTime((prevTime) => {
            return {
                ...prevTime,
                hour: d.getHours(),
                minute: d.getMinutes(),
                second: d.getSeconds()
            }
        })

        if (d.getHours() >= 6 && d.getHours() < 18) {
            setDayOrNight((prevState) => ({
                ...prevState,
                day: true,
                night: false
            }))

        } else if (d.getHours() >= 18 && d.getHours() < 24) {
            console.log("night")
            setDayOrNight((prevState) => ({
                ...prevState,
                day: false,
                night: true
            }))
        } else if (d.getHours() >= 0 && d.getHours() < 6) {
            console.log("night 2")

            setDayOrNight((prevState) => ({
                ...prevState,
                day: false,
                night: true
            }))
        }
    }

    useEffect(() => {

        if (localStorage.getItem('introCorporateCounter') === null){
            localStorage.setItem('introCorporateCounter', JSON.stringify({'count': 1}))
        } else {
            const item = localStorage.getItem('introCorporateCounter')
            const counter = JSON.parse(item)
            if (counter.count === 1) {
                setIntro(true)
                console.log("local not empty")
            }
        }

        var bgMusic = document.getElementById('natureSound')
        // bgMusic.volume = .12 // Set audio volume
        bgMusic.volume = 0 // Set audio volume

        var navbar = document.getElementById('navbar')
        navbar.classList.add('landing-page') // Adds navigation class to navbar

        
        window.addEventListener('resize', areaBlobs) // On window resize, re-calculate blob position
        
        getDate() // Get current time on initial render
        const timer = setInterval(getDate, 1000); // Get current time


        const options = {
            rootMargin: "-150px 0px 0px 120px",
            threshold: .25
        }

        const mobileObserver = new IntersectionObserver(entries => {
            for (let i = 0; i < entries.length; i++) {
                entries[i].target.classList.toggle("show", entries[i].isIntersecting)
            }
        }, options)

        const mobileBtns = document.querySelectorAll(".mobile-btn-v2")

        mobileBtns.forEach(btn => {
            mobileObserver.observe(btn)
        })

        let introHide
        if (!isIntro) {
            var introVideoV2 = document.getElementById("introVideoV2")
            
            introVideoV2.oncanplay = () => {
                setIsLoading(false) 
            }
            introVideoV2.addEventListener('ended', () => {
                console.log("ended")
                introVideoV2.style.opacity = 0
                introHide = setTimeout(() => {
                    introVideoV2.setAttribute('src', "")
                    showBlob()
                    hideIntro()
                }, 400)
            })
        }
        
        
        // Code cleanup
        return () => {
            window.removeEventListener('resize', areaBlobs)
            // navbar.classList.remove('navigation')

            clearInterval(timer);
            mobileObserver.disconnect()
            
            clearTimeout(introHide)

            navbar.classList.remove('landing-page')
        }
    }, [])

    useEffect(() => {
        if (isIntro) {
            var img = document.querySelector(".img-mapper-img")
            img.setAttribute('draggable', false);
    
            var mapper = document.getElementById('img-mapper')
            mapper.style.opacity = 1
            mapper.style.zIndex = "101"

            return () => {
                img.removeAttribute("draggable");
            }
        } else if (!isIntro) {
            var mapper = document.getElementById('img-mapper')
            mapper.style.opacity = 0
            mapper.style.zIndex = "0"

        }

    }, [isIntro])


    const hideIntro = () => {
        // var videoIntro = document.getElementById('videoIntro')
        // videoIntro.style.display = "none"
        setIntro(true)
        // setIntro((prevState) => ({
        //     ...prevState,
        //     isDone: true 
        // }))
    }
    

    const areaBlobs = (val) => {
        modalHide()
        if (val === "initial") {
            // showBlob()
        }

        setWindowWidth(windowSize)

        let areas, areaCoords

        var map = document.querySelectorAll("map.img-mapper-map")
        areas = map.item(0).areas    

        for (let i = 0; i < areas.length; i++) {
            
            areaCoords = areas[i].getAttribute('coords')
        
            let center = getAreaCenter(areaCoords)

            if (i == 0) {
                setBlobPos1((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } 
            
            else if (i == 1) {
                setBlobPos2((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            }
            // } else if (i == 2) {
            //     setBlobPos3((prevState) => ({
            //         ...prevState,
            //         x: center[0],
            //         y: center[1]        
            //     }))
            // } else if (i == 3) {
            //     setBlobPos4((prevState) => ({
            //         ...prevState,
            //         x: center[0],
            //         y: center[1]        
            //     }))
            // } else if (i == 4) {
            //     setBlobPos5((prevState) => ({
            //         ...prevState,
            //         x: center[0],
            //         y: center[1]        
            //     }))
            // }
        }
    }

    const modalShow = (val) => {

        setisModalVisible(true)
        setModalContent((prevState) => ({
            ...prevState,
            title: val.title
        }))

        setPos((prevState) => {
            return {
                ...prevState,
                x: val.center[0],
                y: val.center[1]
            }
        })

    }

    const modalMobileShow = (val) => {

        setisModalVisible(true)
        setModalContent((prevState) => ({
            ...prevState,
            title: val
        }))

    }

    const modalHide = () => {
        setisModalVisible(false)
        
    }

    const ImageMap = {
        name: 'home-map',
        areas: coords,
    }

    const hideBlob = () => {
        let blobs = document.querySelectorAll('div.blob')

        for (let i = 0 ; i < blobs.length; i++) {
            // if (window.getComputedStyle(blobs.item(i)).animationPlayState !== 'paused') {
            //     blobs.item(i).style.animationPlayState = "paused"
            // }
            // if (window.getComputedStyle(blobs.item(i)).opacity !== '0') {
            //     blobs.item(i).style.opacity = '0'
            //     setTimeout(() => { blobs.item(i).style.display = 'none' }, 400)
            // }
        }
        
    }
    const showBlob = () => {
        let blobs = document.querySelectorAll('div.blob')

        for (let i = 0 ; i < blobs.length; i++) {
            // if (window.getComputedStyle(blobs.item(i)).animationPlayState !== 'running') {
            //     blobs.item(i).style.animationPlayState = "running"
            // }
            if (window.getComputedStyle(blobs.item(i)).opacity != '1') {
                blobs.item(i).style.display = 'block'
                blobs.item(i).style.opacity = '1'
            }
        }
    }

    const closeNav = () => {
        // var mobile = document.getElementById('mobile')
        // mobile.classList.remove('show')
    }


    return (
        <>
            { isLoading ? <LoadScreen /> : ''}
            <div className="content main" id="contentMain" onClick={ closeNav }>

                <iframe src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/silence.mp3" allow="autoplay" id="audio/mp3" style={{ display: "none" }}></iframe>

                <audio id="natureSound" autoPlay loop style={{ display: "none" }}>
                    <source src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/Background.mp3" type="audio/mpeg" />
                    <source src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/Background.ogg" type="audio/ogg" />
                </audio>


                <div className="mainContent">
                <Controller />
                    { isModalVisible ? <Modal title={ modalContent.title } posX={ pos.x } posY={ pos.y } hideModal={ modalHide } timestamp={ isDayOrNight.day ? "day" : "night" } />:""}

                    
                    {/* <Blob x={ blobPos1.x } y={ blobPos1.y - (blobPos1.y * .3) } /> */}
                    <Blob x={ blobPos1.x } y={ blobPos1.y - (blobPos1.y * .125) } />
                    {/* <Blob x={ blobPos2.x - (blobPos2.x * .032) } y={ blobPos2.y - (blobPos2.y * .125) } /> */}
                    <Blob x={ blobPos2.x - (blobPos2.x * .032) } y={ blobPos2.y - (blobPos2.y * .125) } />
                    {/* <Blob x={ blobPos3.x - (blobPos3.x * .02) } y={ blobPos3.y - (blobPos3.y * .2) } />
                    <Blob x={ blobPos4.x - (blobPos4.x * .072) } y={ blobPos4.y - (blobPos4.y * .16) } />
                    <Blob x={ blobPos5.x - (blobPos5.x * .072) } y={ blobPos5.y - (blobPos5.y * .16) } /> */}

                    <ImageMapper
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/blank.png"
                        map={ ImageMap }
                        responsive={true} 
                        parentWidth={ windowWidth }
                        onImageClick={ modalHide }
                        onClick={ (area) => { modalShow(area) }}
                        onMouseEnter={ hideBlob }
                        onMouseLeave={ showBlob }
                        onLoad={ () => { 
                            areaBlobs("initial") 
                            if (isIntro) {
                                setIsLoading(false) 
                                showBlob()
                            }
                        } }
                    />
                    <div className="video-placeholder">
                        { isDayOrNight.day ? 
                            <img src={ imgPlaceholderLinks.day } alt="" id="daytimeImgPlaceholder" loading="eager"/>
                            : <></>
                        }
                        { isDayOrNight.night ? 
                            <img src={ imgPlaceholderLinks.night } alt="" id="daytimeImgPlaceholder" loading="eager"/>
                            : <></>
                        }
                        <div className="container" id="">
                            {
                                !isIntro ?    

                                    <video className="intro-video-v2" src={ isDayOrNight.day ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/2nd_revision_03-24-24_intro%20(1080p).mp4" : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/video/2nd_revision_03-24-24_intro%20(1080p).mp4"} id="introVideoV2" autoPlay muted playsInline></video>

                                    
                                : <></>
                            }
                            { isIntro ?
                                <video className="responsive-iframe"src={ isDayOrNight.day ? videoLinks.day : videoLinks.night } id="videoRender" width="640" height="360" autoPlay muted loop></video>
                                : <></>
                            }
                            
                        </div>
                    </div>
                </div>
                
                
                <div className="mobile-control-v2" id="mobileControlV2">
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/phirst-park-homes/pphi-banner-v2.JPG"
                        title="PHirst Park Homes"
                        link="/phirst-park-homes"
                        class="others"
                        showModal={() => { modalMobileShow("PhirstParkHomes") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/editions-hermosa-tablet.jpg"
                        title="PHirst Editions"
                        link="/phirst-editions"
                        class="others"
                        showModal={() => { modalMobileShow("Editions") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/sights/marketing-tent-main-bg.jpg"
                        title="PHirst Sights"
                        link="/phirst-sights"
                        class="others"
                        showModal={() => { modalMobileShow("Sights") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/impressions-tablet.jpg"
                        title="PHirst Impressions"
                        link="/phirst-impressions"
                        class="others"
                        showModal={() => { modalMobileShow("Impressions") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/centrale/centrale-banner-bg-2.jpg"
                        title="PHirst Centrale"
                        link="/phirst-centrale"
                        class="others"
                        showModal={() => { modalMobileShow("Centrale") }}
                    />

                </div>
            </div>
        </>
    )
}

const MobileButton = (props) => {
    return (
        <a href={ props.link } style={{ textDecoration: "none" }}>
            <div className="mobile-button">
                <div className="img-container" style={{ backgroundImage: "url(" + props.url + ")"}}>
                    {/* <img className="button-image" src={ props.url } alt="" /> */}
                </div>
                
                <h5 className="button-title">{ props.title }</h5>
            </div>
        </a>
    )
}

const MobileButtonV2 = (props) => {

    return (
        <>
            <div className={ "mobile-btn-v2 " +  props.class } style={{ backgroundImage: "url(" + props.url + ")"}} onClick={ props.showModal }>
                {/* <Link to={ props.link }>
                </Link> */}
                <div className="color-overlay"></div>
                    {/* <img className="btn-img" src={ props.url } alt="" /> */}
                <h5 className="btn-title">{ props.title }</h5>
            </div>
        </>
    )
}


export default Landing;