import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";

import '../css/careers.css'
import '../css/career-cards.css'
import Footer from "../../partials/components/Footer";
import LoadScreen from "../../others/components/loadScreen";

import JobForm from "./form";
import BackBtn from "../../others/components/backBtn";

const PhirstCareers = () => {
    // const bannerFront = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-front-bg-v2.jpg'
    const bannerFront = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/FINAL%20COVER%20PHOTO%20OPTION%202_1%20copy.jpg'
    const bannerBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-bg-2-v2.jpg'
    const bannerBg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-bg-3.jpg'
    const bannerBg3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-bg-4-v2.jpg'

    const phirstBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/phirst-bg-v1.jpg'
    const dividerBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-bg-1.jpg'
    const careerLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/phirst-careers-logo.png'
    const [ isIframeLoaded, setIframeState ] = useState(false)
    
    const [ loadState, setLoadState ] = useState(false)
    
    const [ careerData, setCareerData ] = useState([])

    const [ isModalShown, setModalState ] = useState(false)
    const [ modalId, setModalId ] = useState()

    const source = axios.CancelToken.source()

    useEffect(() => {
        setLoadState(true)
    }, [])

    const slideCounter = useRef(1)

    useEffect(() => {

        getJobs(source.token)

        return () => {
            source.cancel('Requested Cancel by cleanup')
        }
    }, [])

    useEffect(() => {
        if (careerData.length != 0) {
            // console.log(careerData)
        }
    }, [careerData])
    
    const getJobs = async (cancelToken) => {
        try {
            // const hostname = window.location.protocol + '//' + window.location.hostname
            // const url = hostname + ':3001/api/retrieve/careers/limit'
            const envDomain = process.env.REACT_APP_DOMAIN_NAME
            const envPort = process.env.REACT_APP_BACKEND_PORT
            // const endpoint = envDomain + ':' + envPort + '/api/retrieve/careers/limit'
            const endpoint = envDomain + '/api/retrieve/careers/limit'
            await axios.get(endpoint, {cancelToken})
              .then(response => {
                // Handle the response data
                setCareerData(response.data)
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
        } catch (err) {
            console.log(err)
        }
    }

    const slideCarousel = (direction) => {
        if(direction === 'left') {
            let carousel = document.getElementById('bannerCarousel')
            if (slideCounter.current > 1) {
                carousel.scrollLeft -= carousel.offsetWidth
                slideCounter.current -= 1
            } else if (slideCounter.current == 1) {
                carousel.scrollLeft += (carousel.offsetWidth * 3)
                slideCounter.current = 4
            }
        } else if (direction === 'right') {
            let carousel = document.getElementById('bannerCarousel')
            if (slideCounter.current < 4) {
                carousel.scrollLeft += carousel.offsetWidth
                slideCounter.current += 1
            } else if ( slideCounter.current == 4) {
                carousel.scrollLeft = 0
                slideCounter.current = 1
            }
        }
    }

    const showModal = (event, id) => {
        event.preventDefault()
        setModalState(true)
        setModalId(id)
        console.log("clicked, ", isModalShown)
    }
    const hideModal = (event) => {
        event.preventDefault()
        setModalState(false)
    }
    const closeModal = () => {
        setModalState(false)
    }

    return (
        <>
            <BackBtn />
            { loadState ? <LoadScreen/> : ''}
            <div className="content careers">
                <div className="banner">
                    <div className="banner-carousel" id="bannerCarousel">
                        <div className="banner-item" style={{ backgroundImage: 'url("'+bannerFront+'")'}}>
                                {/* <img className="banner-front-content desktop" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-front-content.png" alt="" /> */}
                                <img className="banner-front-content desktop" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/FINAL%20COVER%20PHOTO%20OPTION%202_1%20copy.jpg" alt="" />
                                <img className="banner-front-content mobile" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/banner-front-content-mobile.jpg" alt="" />
                            <div className="background-overlay"></div>
                        </div>
                        <div className="banner-item" style={{ backgroundImage: 'url("'+bannerBg+'")'}}>
                            <div className="banner-content">
                                <img className="banner-logo-2" src={careerLogo} alt="" />
                                <h2>PHirst Careers - Building Careers that Last</h2>
                                <a href="/about-us">Learn More</a>
                            </div>
                            <div className="background-overlay"></div>
                        </div>
                        <div className="banner-item" style={{ backgroundImage: 'url("'+bannerBg2+'")'}}>
                            <div className="banner-content">
                                {/* <img className="banner-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/PHIRST_LOGO.png" alt="" /> */}
                                <img className="banner-logo-2" src={careerLogo} alt="" />
                                <h2>Make your next career move at PHirst</h2>
                                <a href="/phirst-careers/jobs">Explore Careers</a>
                            </div>
                            <div className="background-overlay"></div>
                        </div>
                        <div className="banner-item" style={{ backgroundImage: 'url("'+bannerBg3+'")'}}>
                            <div className="banner-content">
                                {/* <img className="banner-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/careers/PHIRST_LOGO.png" alt="" /> */}
                                <img className="banner-logo-2" src={careerLogo} alt="" />
                                <h2>Join our team as an Accounting Specialist</h2>
                                <a href="/phirst-careers/jobs">Apply Now!</a>
                            </div>
                            <div className="background-overlay"></div>
                        </div>
                    </div>
                    <div className="carousel-left-control">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-left-btn-v1.png" alt="" onClick={() => { slideCarousel('left') }}/>
                    </div>
                    <div className="carousel-right-control">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/banner/cards/card-right-btn-v1.png" alt=""  onClick={() => { slideCarousel('right') }}/>
                    </div>
                </div>

                <div className="career-cards">
                    <h4>Career Opportunities</h4>
                    <iframe src="https://wordpress-980726-3489233.cloudwaysapps.com/index.php/phirst-careers/" onLoad={ () => { setIframeState(true) }} id="wordpressIframe"></iframe>
                    <a href="/careers">See more</a>
                </div>

                <div className="career-cards-v2">
                    <h2>Career Opportunities</h2>
                    <div className="cards-container">
                        { careerData.map((item, index) => {
                            return <CardJobComponent key={index} {...item} handleClick={showModal}/>
                        }) }
                    </div>
                    <a href="phirst-careers/jobs">Explore more Opportunities</a>
                </div>

                <div className="why-phirst" style={{ backgroundImage: 'url("'+phirstBg+'")'}}>
                    <div className="text-content">
                        <h4>Why PHirst?</h4>
                        <p>At PHirst, we believe that everyone deserves a home they can be proud of. That's why we're committed to providing quality, affordable housing that meets the needs of Filipino families. We're a company that's built on strong values of integrity, excellence, and innovation, and we're always looking for talented individuals who share those values.</p>
                        <p>Our company culture is focused on teamwork, respect, and collaboration. We believe that every employee has something valuable to contribute, and we're committed to creating an environment where everyone feels supported, empowered, and motivated to do their best work.</p>
                        <p>If you're looking for a career where you can make a difference in the lives of others while also growing professionally, PHirst might be the right fit for you.</p>
                    </div>
                </div>
                <div className="divider-phirst" style={{ backgroundImage: 'url("'+dividerBg+'")' }}>
                    <div className="text-content">
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/about-us/values/values-social-impact-v1.png" alt="" />
                        <p>Connect and make your #PHirstCareer experience exciting as ever!</p>
                        <a href="phirst-careers/jobs">Explore more Career Opportunities</a>
                    </div>
                    <div className="background-overlay"></div>
                </div>
                <div className="map-embed">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.4172048086848!2d121.02635266961563!3d14.560921697149904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c90749ca2617%3A0xc0addd6596124c18!2sPacific%20Star%20Bldg!5e0!3m2!1sen!2sph!4v1684362349688!5m2!1sen!2sph" width="600" height="450" style={{ border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <Outlet />
                <Footer />
                <JobForm modalState={isModalShown} handleClick={hideModal} jobId={modalId} closeModal={closeModal}/>
            </div>
        </>
    )
}

const CardJobComponent = (props) => {

    const [ cSpecs, setSpecs ] = useState([])
    const [ isSpecsNotEmpty, setSpecsState ] = useState(false) // Specs

    const source = axios.CancelToken.source()

    useEffect(() => {
        // console.log(props)
        getCareerSpecs()
    }, [])

    const getCareerSpecs = async () => {
        try {
            const envDomain = process.env.REACT_APP_DOMAIN_NAME
            const endpoint = envDomain + '/api/retrieve/careers/specs/'
            await axios.get(endpoint + props.job_id, { cancelToken: source.token})
                .then(response => {
                    if (response.data.length !== 0) {
                        setSpecs(response.data)
                        setSpecsState(true)
                        console.log('Success')
                    } else {
                        setSpecsState(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } catch(err) {
            console.log("Career specs retrieval error: ", err)
        }
    }

    return (
        <>
            <div className="card-item">
                <div className="card-content">
                    <h2>{ props.job_title }</h2>
                    {/* <p>{ props.job_specs }</p> */}
                    <div className="specs">
                        <ul id="specsList">
                            { isSpecsNotEmpty ? 
                                cSpecs.map((item, index) => {
                                    return <li key={index}>{ item.specs_content}</li>
                                }) : 
                                <></>
                            }
                        </ul>
                    </div>
                    <a href="#" onClick={(event) => {props.handleClick(event, props.job_id)}}>Apply Now!</a>
                </div>
                <div className="background-overlay"></div>
            </div>
        </>
    )
}

export default PhirstCareers